import React from 'react';

import { Menu, Item, Separator, Submenu } from 'react-contexify';
import { getBooleanStatus, MENU_OPERATIONS } from 'src/_config';

interface LeftContexMenuProps {
    getParams: (data: { id: number, userName: string, customize_nickname: any, operation: string }) => void
    profile: any;
    isLoginUsers: boolean;
}

const LeftBarPersonContextMenu = ({ profile, getParams, isLoginUsers }: LeftContexMenuProps) => {
    // console.log("@@profile: ", profile);
    // console.log("@@isLoginUsers: ", isLoginUsers);


    const handleItemClick = (e: any, operation: string) => {
        if (isLoginUsers) {
            getParams(
                {
                    id: e.props.profile.id,
                    userName: e.props.profile.username,
                    customize_nickname: null,
                    operation: operation
                }
            )
        } else {
            getParams(
                {
                    id: e.props.profile.contact_user.id,
                    userName: e.props.profile.contact_user.username,
                    customize_nickname: e.props.profile.customize_nickname,
                    operation: operation
                }
            )
        }
    }

    const handleItemClickCall = (event: any) => {
        console.log('event')
    }

    return (
        <Menu id='menu_id' className="sidebar-rightclick-menu">
            {
                // profile && profile.contact_user && [1, 2, 3].includes(profile.contact_user.visible_status) ?
                <Item onClick={(event) => handleItemClick(event, MENU_OPERATIONS.SEND_PM)}>Send a PM</Item>
                // :
                // null
            }
            <Item onClick={(event) => handleItemClickCall(event)}>Call</Item>
            <Item onClick={(event) => handleItemClickCall(event)}>Video Call</Item>
            <Item onClick={(event) => handleItemClickCall(event)}>Start Webcam</Item>
            {
                profile && getBooleanStatus(profile.is_favourite) ?
                    null :
                    <>
                        <Item onClick={(event) => handleItemClick(event, MENU_OPERATIONS.ADD_TO_FAVOURITE_CONTACT)}>Add as favourite</Item>
                        <Separator />
                    </>

            }


            <Item onClick={(event) => handleItemClick(event, MENU_OPERATIONS.REMOVE_FROM_CONTACT_LIST)}>Remove</Item>
            <Item >Add to Contact List</Item>
            <Item onClick={(event) => handleItemClick(event, MENU_OPERATIONS.ADD_TO_BLOCK_LIST)}>Add to Blocked List</Item>
            <Separator />
            {/* <Item onClick={(event) => handleItemClick(event.props.profile)}>Delete</Item> */}
            <Item >Invite</Item>
            <Item onClick={(event) => handleItemClick(event, MENU_OPERATIONS.VIEW_PROFILE)}>View Profile</Item>
            <Item onClick={(event) => handleItemClick(event, MENU_OPERATIONS.SEND_FILE)}>Send a File</Item>
            <Item onClick={(event) => handleItemClick(event, MENU_OPERATIONS.CUSTOMIZED_NICKNAME)}>Customize Nickname</Item>
            <Separator />
            <Item onClick={(e) => handleItemClick(e, MENU_OPERATIONS.SEND_VIRTUAL_GIFT)}>Send a Virtual Gift</Item>
            {/* <Item onClick={(e) => handleItemClick(e, MENU_OPERATIONS.BUY_GIFT_SUBSCRIPTION)}>Buy a Gift Subscription</Item> */}

            <Submenu label="Send a Gift Subscription">
                <Item >Gold</Item>
                <Submenu label="VIP">
                    <Item>Orchid</Item>
                    <Item>Pink</Item>
                </Submenu>
                <Item>Coral</Item>
                <Item>Steel Blue</Item>
            </Submenu>
        </Menu>
    );
};

export default LeftBarPersonContextMenu;