import { ActionExtended } from 'src/_common/interfaces/ActionExtended';
import { User } from 'src/_common/interfaces/models/user';
import { ACTIONS } from 'src/_config'

export interface UserReducer {
  user: User | null;
  token: string | null;
  isAuthenticated: boolean;
  changePasswordModalShow: boolean;
  multiRecipientMessageModalShow: boolean;
  shareWithOtherContactListModalShow: {
    isOpen: boolean,
    url: string,
    roomId: any,
  };
  findAndAddUserModalShow: {
    isOpen: boolean,
    type: string | null,
    user_id: number | null | undefined
  };
  socketContainer: null,
  loggedInFromOtherLocation: any,
}

const initialState: UserReducer = {
  user: null,
  token: null,
  isAuthenticated: false,
  changePasswordModalShow: false,
  multiRecipientMessageModalShow: false,
  shareWithOtherContactListModalShow: {
    isOpen: false,
    url: '',
    roomId: null
  },
  findAndAddUserModalShow: {
    isOpen: false,
    type: null,
    user_id: null
  },
  socketContainer: null,
  loggedInFromOtherLocation: null,
};

const userReducer = (state = initialState, action: ActionExtended) => {
  switch (action.type) {
    case ACTIONS.USER.LOGIN:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isAuthenticated: true,
      };
    case ACTIONS.USER.LOGOUT:
      return {
        ...state,
        user: null,
        token: null,
        isAuthenticated: false,
      };
    case ACTIONS.USER.ME:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };
    case ACTIONS.USER.ONLINE_STATUS_CHANGE:
      let tempStatus = {
        onlineStatus: action.payload
      }
      var newUser = { ...state.user, ...tempStatus }
      return {
        ...state,
        user: newUser,
      };
    case ACTIONS.USER.PROFILE:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      }
    case ACTIONS.USER.MENU.CHANGE_PASSWORD_MENU:
      return {
        ...state,
        changePasswordModalShow: action.payload
      }

    case ACTIONS.USER.SHOW_MULTI_RECIPIENT_MESSAGE_MODAL:
      return {
        ...state,
        multiRecipientMessageModalShow: action.payload
      }

    case ACTIONS.USER.SHARE_WITH_OTHER_CONTACT:
      return {
        ...state,
        shareWithOtherContactListModalShow: {
          isOpen: action.payload.isOpen,
          url: action.payload.url,
          roomId: action.payload.roomId,
        }
      }

    case ACTIONS.USER_PREFERENCE.FIND_AND_ADD_USER:
      return {
        ...state,
        findAndAddUserModalShow: {
          isOpen: action.payload.isOpen,
          type: action.payload.type,
          user_id: action.payload.user_id
        }
      }
    case ACTIONS.USER.SOCKET_INSTANCE_CONTAINER:
      return {
        ...state,
        socketContainer: action.payload
      };

    case ACTIONS.USER.MANAGE_ABOUT_MESSAGE:
      var tempUser = { ...state.user }

      return {
        ...state,
        user: {
          ...tempUser,
          about: action.payload
        }
      }
    case ACTIONS.USER.LOGGEDIN_FROM_OTHER_LOCATION:
      return {
        ...state,
        loggedInFromOtherLocation: action.payload
      }
      break;
    default:
      return state;
  }
};

export default userReducer;
