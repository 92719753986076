import React, { useState, useEffect } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import MetisMenu from '@metismenu/react';
import { contextMenu } from 'react-contexify';
import { NavLink, Link, useHistory } from "react-router-dom";
import SocketLoader from 'src/socket/socketLoader';
import LeftBarPersonContextMenu from '../clickContextMenu/leftbarPersonContextMenu';
import StatusToggleMenu from '../clickContextMenu/profileStatusContextMenu'
import { useAppUserDetailsSelector, useAppLoggedinFromOtherLocation } from 'src/_common/hooks/selectors/userSelector'
import { useAppLeftMenuItemListSelector, useAppInstanceInvitedUsers, useAppRoomDetailsSelector } from 'src/_common/hooks/selectors/groupCategorySelector';
import { useGroupCategoryApi } from 'src/_common/hooks/actions/groupCategory/appGroupCategoryApiHook'
import { useAppNotebookList } from 'src/_common/hooks/selectors/notebookSelector';
import { URLS, getNameInitials, getBooleanStatus, getStatusColor, getAvailabiltyStatusText, MENU_OPERATIONS, getValueFromArrayOfObject, CRYPTO_SECRET_KEY, PM_TYPE, CUSTOM_MESSAGE, STORAGE, APP_VERSION, trimTo, getSubscriptionColor } from 'src/_config';
import { UpdateShowHideProfilePicture } from 'src/_common/interfaces/ApiReqRes';
import { useUserApi } from 'src/_common/hooks/actions/user/appUserApiHook';
import { usePmWindowApi } from 'src/_common/hooks/actions/pmWindow/appPmWindowApiHook';
import { useToaster } from 'src/_common/hooks/actions/common/appToasterHook';
import ViewProfileModal from 'src/components/commonModals/viewProfileModal/viewProfileModal';
import RoomInvitationNotificationModal from '../commonModals/roomInvitationNotificationModal/roomInvitationNotificationModal';
import StickerBuyModal from 'src/components/commonModals/stickerBuyModal/stickerBuyModal';
import clsx from 'clsx';
import CustomizedNicknameModal from '../groupsCategory/roomsDetail/modal/customizedNicknameModal';
import { useAppPmWindowAction } from 'src/_common/hooks/actions/pmWindow/appPmWindowActionHook';
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook';
import { useAppGroupCategoryAction } from 'src/_common/hooks/actions/groupCategory/appGroupCategoryActionHook';
import { usePmDigSoundSelector } from 'src/_common/hooks/selectors/pmWindowSelector';
const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

function Sidebar() {
  const history = useHistory()
  const [alert, setAlert] = useState<any>(null);
  const userSelector = useAppUserDetailsSelector()
  const loggedInFromOtherLocation = useAppLoggedinFromOtherLocation()
  const userAction = useAppUserAction()
  const groupCategoryAction = useAppGroupCategoryAction()
  const notebookListSelector = useAppNotebookList()
  const leftMenuItemDetails = useAppLeftMenuItemListSelector()
  const instanceInvitedUsersSelector = useAppInstanceInvitedUsers()
  const groupCategoryApi = useGroupCategoryApi()
  const pmWindowApi = usePmWindowApi()
  const pmWindowAction = useAppPmWindowAction()
  const userApi = useUserApi()
  const toast = useToaster()
  const [membersData, setMembersData] = useState<any>()
  const [currentUser, setCurrentUser] = useState<any>(null)
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false)
  const [loginUsersData, setLoginUsersData] = useState<any>()
  const [isLoginUser, setIsLoginUser] = useState<boolean>(false)
  const [noteBookNewCount, setNotebookNewCount] = useState<number>(0)
  const [selectedUserId, setSelectedUserId] = useState<number>()
  const [showRoomInvitationModal, setShowRoomInvitationModal] = useState<boolean>(false)
  const [showStickerBuyModal, setShowStickerBuyModal] = useState<boolean>(false)
  const [selectedUser, setSelectedUser] = useState<any[]>([])

  const [showCustomizedNicknameModal, setCustomizedNicknameModal] = useState<boolean>(false)

  const pmWindowDigSoundSelector = usePmDigSoundSelector()
  const roomDetailsSelector = useAppRoomDetailsSelector()

  const customizedNicknameModalClose = () => {
    if (showCustomizedNicknameModal) {
      setMembersData(null)
      setCustomizedNicknameModal(false)
    }
  }

  const handleViewProfile = (id: number) => {
    setSelectedUserId(id)
    setShowProfileModal(true)
  }

  const onViewProfileModalClose = () => {
    setShowProfileModal(false)
  }

  const addUserAsFavouriteFromContact = (id: number) => {
    const params = {
      contact_user_id: id
    }
    groupCategoryApi.callAddUserAsFavouriteFromContact(params, (message: string, resp: any) => {
      if (resp) { }
    }, (message: string) => {
      console.error("Error at add as favourite contact");
    })
  }

  const handleAddToBlockList = (id: number) => {
    if (id === userSelector?.id) {
      toast.error(CUSTOM_MESSAGE.OTHERS.BLOCK_HIMSELF)
    } else {

    }
  }

  //Function for click on send pm
  const handleSendPm = (id: number) => {
    const params = {
      user_id: id
    }

    pmWindowApi.callSendPms(params, (message: string, resp: any) => {
      if (resp) {
        const userId = cryptr.encrypt(resp.id)
        history.replace('')
        history.push(`pm/${userId}`)
      } else {
        toast.error(message)
      }
    }, (message: string, resp: any) => {
      toast.error(message)
    })
  }

  const handleRedirectToPm = (id: number) => {
    const params = {
      pm_id: id
    }
    pmWindowApi.callReadPm(params, (message: string, resp: any) => {
      if (resp) {
        const pmId = cryptr.encrypt(id)
        pmWindowAction.fromRouteHandler(id)
        history.replace('')
        history.push(`pm/${pmId}`)
      } else {
        toast.error(message)
      }
    }, (message: string, resp: any) => {
      toast.error(message)
    })
  }

  const hideAlert = () => {
    setAlert(null);
  }

  const showAlert = (e: React.MouseEvent, userId: any) => {
    e && e.preventDefault()
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes"
        cancelBtnText="No"
        cancelBtnBsStyle="success"
        confirmBtnBsStyle="danger"
        allowEscape={false}
        closeOnClickOutside={false}
        title="Remove from favourite list"
        onConfirm={() => removeFavourite({ contact_user_id: userId })}
        onCancel={hideAlert}
        focusCancelBtn={true}
      >
        Are you sure to remove from favourite contact list?
      </SweetAlert>
    );
  }


  //for stricker
  const openStickerBuyModal = (id: number) => {
    // e.preventDefault()
    // setByStickerModalType('ownStickerBuy')
    setSelectedUser([id])
    setShowStickerBuyModal(true)
  }
  const handleOnCloseSticker = () => {
    setShowStickerBuyModal(false)
    setSelectedUser([])
    // setSelectedContactList([])
    // setByStickerModalType('')
    // getStickerCategory()

  }


  // right click context menu

  const onContextMenuClick = (e: any, profile: any) => {
    e.preventDefault();
    setMembersData(profile)
    setLoginUsersData(userSelector)
    setIsLoginUser(false)

    // if (!profile.is_block) {
    //   if (!(profile.contact_user.id === userSelector?.id)) {
    contextMenu.show({
      id: 'menu_id',
      event: e,
      props: {
        profile: profile
      }
    });
    //   }
    // }
  }

  const onSelfContextMenuClick = (e: any, profile: any) => {
    e.preventDefault();
    setMembersData(profile)
    setLoginUsersData(profile)
    setIsLoginUser(true)
    contextMenu.show({
      id: 'menu_id',
      event: e,
      props: {
        profile: profile
      }
    });
  }

  const contextMenuOperationParams = (data: { id: number, userName: string, customize_nickname: any; operation: string }) => {
    switch (data.operation) {
      case MENU_OPERATIONS.SEND_PM:
        handleSendPm(data.id)
        break;
      case MENU_OPERATIONS.VIEW_PROFILE:
        handleViewProfile(data.id)
        break;
      case MENU_OPERATIONS.ADD_TO_FAVOURITE_CONTACT:
        addUserAsFavouriteFromContact(data.id)
        break;
      case MENU_OPERATIONS.SEND_VIRTUAL_GIFT:
        openStickerBuyModal(data.id)
        break;
      case MENU_OPERATIONS.CUSTOMIZED_NICKNAME:
        setCustomizedNicknameModal(true)
        setMembersData({
          id: data.id,
          userName: data.userName,
          customize_nickname: data.customize_nickname
        })
        break;
      case MENU_OPERATIONS.ADD_TO_BLOCK_LIST:
        handleAddToBlockList(data.id)
        break;
      default:
        break;
    }
  }

  const statusToggle = (e: any, status: any) => {
    contextMenu.show({
      id: 'status_toggle_id',
      event: e,
      props: {
        status: status
      }
    });
  }

  const toggleShowHideProfilePicture = (e: any) => {
    const params: UpdateShowHideProfilePicture = {
      avatar_visible: e == true ? 1 : 0
    }

    userApi.callUpdateProfilePictureShowHide(params, (message: string, resp: any) => {
      if (resp) {
        toast.success(message)
      } else {
        toast.error(message)
      }
    }, (message: string, resp: any) => {
      toast.error(message)
    })

  }


  const getLeftMenuItemsList = () => {
    groupCategoryApi.callLeftMenuItemsList((message: string, resp: any) => {
      if (resp && resp.data) {
      }
    }, (message: string) => {
      // toast.error(message)
    })
  }

  const removeFavourite = (params: any) => {
    // e.preventDefault();
    // const params = {
    //   contact_user_id: id
    // }
    groupCategoryApi.callRemoveFavouriteContact(params, (message: string, resp: any) => {
      if (resp) {
        hideAlert()
        getLeftMenuItemsList()
      }
    }, (message: string) => {
      hideAlert()
    })
  }


  useEffect(() => {
    getLeftMenuItemsList()
  }, [])

  //Used for notebook Un read message count
  useEffect(() => {
    if (notebookListSelector && notebookListSelector.length) {
      let count = notebookListSelector.filter((note: any) => note.is_viewed === 0);
      if (count && count.length) {
        setNotebookNewCount(count.length)
      }
    }
  }, [notebookListSelector])

  //Instance Invited Users Selector Update effect
  // useEffect(() => {
  //   if (instanceInvitedUsersSelector && instanceInvitedUsersSelector.length) {
  //     instanceInvitedUsersSelector.map((x: any) => {
  //       if (x.id == (userSelector && userSelector.id)) {
  //         toast.error(x.msg)
  //       }
  //     })
  //   }
  // }, [instanceInvitedUsersSelector])

  const LogOut = () => {
    localStorage.removeItem(STORAGE)
    localStorage.setItem('isAdult', '0');
    groupCategoryAction.emptyRoomListCategoryWise()
    userAction.loggedInFromOtherLocation(null)
    userAction.logout()
    history.push(URLS.LOGIN)
  }

  useEffect(() => {
    if (loggedInFromOtherLocation) {
      if (userSelector?.id === loggedInFromOtherLocation?.user_id) {
        setAlert(
          <SweetAlert
            warning
            // showCancel
            confirmBtnText="Ok"
            // cancelBtnText="No"
            // cancelBtnBsStyle="success"
            confirmBtnBsStyle="success"
            allowEscape={false}
            closeOnClickOutside={false}
            title="Alert"
            onConfirm={() => LogOut()}
          // onCancel={hideAlert}
          // focusCancelBtn={false}
          >
            {
              loggedInFromOtherLocation.message
            }
          </SweetAlert>
        );
      }
    }
  }, [loggedInFromOtherLocation, userSelector])

  const getPrivacySettingsRoomIamInOptions = (settings: any[]) => {
    for (let i = 0; i < settings.length; i++) {
      let val = settings[i].val ? settings[i].val : '';
      return val;
    }
  }

  //Dig Sound notification

  const playSound = () => {
    const audio = new Audio(pmWindowDigSoundSelector.sound.sound.thumb);
    audio.play();
  }

  useEffect(() => {
    if (pmWindowDigSoundSelector && pmWindowDigSoundSelector.sound && pmWindowDigSoundSelector.sound.sound && pmWindowDigSoundSelector.sound.sound.original) {
      if (pmWindowDigSoundSelector && pmWindowDigSoundSelector.users && pmWindowDigSoundSelector.users.length) {
        toast.success(pmWindowDigSoundSelector.users[0].message)
      }
      setTimeout(() => {
        var soundButton = document.getElementById("playSoundBtn");
        if (soundButton) {
          soundButton.click();
        }
      }, 150)

      setTimeout(() => {
        pmWindowAction.digSoundSocketData(null)
      }, 300)
    }
  }, [pmWindowDigSoundSelector])


  return (
    <React.Fragment>
      <div id="sidebar-menu" className="left-sidebar">
        {alert}
        <button type="button" style={{ display: "none" }} id="playSoundBtn" onClick={playSound}></button>
        <div className="sidebar-profile-panel d-flex align-items-center mb-4">
          <div className="sidebar-profile-pic">
            <div className="sidebar-profile-pic-inner">
              {
                userSelector && userSelector.avatar && userSelector.avatar.thumb ?
                  <img src={userSelector.avatar.thumb} height={'62px'} width={'62px'} /> :
                  (<span>{getNameInitials(userSelector?.username)}</span>)
              }
            </div>
            <span className={userSelector && userSelector.visible_status ? 'user-status st-' + userSelector.visible_status : 'user-status st-1'} onClick={(e) => statusToggle(e, 'status')} />
          </div>
          <div className="sidebar-profile-info">
            {/* Change Username Color when user enterd into the room & color will be based on room subscription if user is owner tof that room */}

            <h4 style={{
              color: getSubscriptionColor(
                roomDetailsSelector && roomDetailsSelector.room ?
                  (roomDetailsSelector.members && roomDetailsSelector.members.length && roomDetailsSelector.members.filter((x: any) => x.user_id === userSelector?.id)[0].is_admin === 3 ?
                    roomDetailsSelector.room
                    : null)
                  : (userSelector && userSelector.is_subscribed ?
                    ({
                      ...userSelector,
                      subscription_info: userSelector.is_subscribed
                    })
                    : null
                  )
              )
            }}>
              {
                userSelector && userSelector.username ? userSelector.username : '..'
              }
            </h4>
            <h5>{getAvailabiltyStatusText(userSelector && userSelector.visible_status)}</h5>
            <span className="about-text" title={userSelector && userSelector.about ? userSelector.about : ''}>
              {
                userSelector && userSelector.about ? trimTo(userSelector.about, 30, true) : null
              }
            </span>
          </div>
        </div>

        <div className="sidebar-profile-tools-panel">
          {/* <a href="#" className="nearby-btn waves-effect">
            <i className="find-nearby-icon mr-2" />
            Find nearby users
          </a> */}
          <NavLink to={URLS.USER.NEAR_BY_USER} className="nearby-btn waves-effect">
            <i className="find-nearby-icon mr-2" />
            Find nearby users
          </NavLink>

          <div className="d-flex justify-content-between align-items-baseline mt-4 mb-4 user-btns-panel">

            <NavLink to={URLS.USER.GROUPS_AND_CATEGORY} activeClassName="active">
              <i className="group-icon mb-1" />
              Groups <br />&amp; Category
            </NavLink>

            <NavLink to={URLS.USER.MANAGE_VOICE_MAIL} activeClassName="active">
              <i className="voicemails-icon mb-1">
                <span className="noticount">
                  {
                    leftMenuItemDetails && leftMenuItemDetails.voice_unread_message_cnt ? leftMenuItemDetails.voice_unread_message_cnt : 0
                  }
                </span>
              </i>
              Voicemails
            </NavLink>

            <NavLink to={URLS.USER.MANAGE_VIDEO_MESSAGE} activeClassName="active">
              <i className="video-msg-icon mb-1">
                <span className="noticount">
                  {
                    leftMenuItemDetails && leftMenuItemDetails.video_unread_message_cnt ? leftMenuItemDetails.video_unread_message_cnt : 0
                  }
                </span>
              </i>
              Video Messages
            </NavLink>

            <NavLink to={URLS.USER.NOTEBOOK} activeClassName="active">
              <i className="notebook-icon mb-1" >
                {/* {
                  noteBookNewCount && noteBookNewCount > 0 ?
                    <span className="noticount">
                      {
                        noteBookNewCount
                      }
                    </span> : null
                } */}

                <span className="noticount">
                  {
                    leftMenuItemDetails && leftMenuItemDetails.notebook_unread_count ? leftMenuItemDetails.notebook_unread_count : 0
                  }
                </span>

              </i>
              Notebook
            </NavLink>

          </div>
        </div>

        {/* Left Menu Start */}

        <MetisMenu>
          <li>
            <Link to="#" className="has-arrow waves-effect">
              <i className="sidebar-icon-1" />
              <span key="t-layouts">Recents PMs {leftMenuItemDetails && leftMenuItemDetails.recent_pms && leftMenuItemDetails.recent_pms.length ? '(' + leftMenuItemDetails.recent_pms.length + ')' : ''}</span>
              {/* {leftMenuItemDetails && leftMenuItemDetails.recent_pms && leftMenuItemDetails.recent_pms.length ? '(' + leftMenuItemDetails.recent_pms.length + ')' : ''} */}
            </Link>
            <ul className="sub-menu" aria-expanded="true">
              {
                leftMenuItemDetails && leftMenuItemDetails.recent_pms && leftMenuItemDetails.recent_pms.length ?
                  leftMenuItemDetails.recent_pms.map((items: any, index: number) => (
                    <li key={index}>
                      <Link to="#">
                        <span className="sub-menu-avatar">
                          {
                            items && items.pm_info && items.pm_info.pm_type === PM_TYPE.SINGLE ?
                              <img className="recent-pms-avatar" src="/img/sidebar-icon-3.png" alt="single" /> :
                              <img className="recent-pms-avatar" src="/img/group-icon.png" alt="group" />
                          }
                        </span>
                        <div className="sub-menu-info" onClick={() => handleRedirectToPm(items.pm_id)}>
                          {items.user_info.customize_nickname && items.user_info.customize_nickname.nickname ? items.user_info.customize_nickname.nickname : items.user_info.username}
                          {/* <span>
                            <i className="oline-tag"
                              style={{ backgroundColor: getStatusColor(items.contact_user.visible_status) }} />{getAvailabiltyStatusText(items.contact_user.visible_status)}
                          </span> */}
                        </div>
                      </Link>
                    </li>
                  )) :
                  <li>
                    <p>No pms found</p>
                  </li>
              }
            </ul>
          </li>

          <li>
            <a href="#" className="has-arrow waves-effect">
              <i className="sidebar-icon-2" />
              <span key="t-layouts">Favourites Contacts {leftMenuItemDetails && leftMenuItemDetails.favourite_contact && leftMenuItemDetails.favourite_contact.length ? '(' + leftMenuItemDetails.favourite_contact.length + ')' : ''}</span>
            </a>
            <ul className="sub-menu" aria-expanded="true">
              {
                leftMenuItemDetails && leftMenuItemDetails.favourite_contact && leftMenuItemDetails.favourite_contact.length ?
                  leftMenuItemDetails.favourite_contact.map((items: any, index: number) => (
                    <li key={index}>
                      <Link to="#"
                        className={clsx({
                          'disable-link': items.is_block
                        })}
                        onContextMenu={(e) => onContextMenuClick(e, items)}
                        onDoubleClick={() => handleSendPm(items.contact_user.id)}
                      >
                        <span className="sub-menu-avatar">
                          {
                            items && items.contact_user && items.contact_user.avatar && getBooleanStatus(items.contact_user.avatar && items.contact_user.avatar.visible_avatar ? items.contact_user.avatar.visible_avatar : 0) && items.contact_user.avatar.thumb ?
                              <img src={items.contact_user.avatar.thumb} alt={items.contact_user.username} /> : (<span className="text-avatar">{getNameInitials(items.contact_user.username)}</span>)
                          }
                        </span>
                        <div className="sub-menu-info" style={{
                          color: getSubscriptionColor(items && items.contact_user && items.contact_user.is_subscribed ? {
                            ...items,
                            subscription_info: items.contact_user.is_subscribed
                          } : null)
                        }}>
                          {items.customize_nickname && items.customize_nickname.nickname ? items.customize_nickname.nickname : items.contact_user.username}
                          <span>
                            <i className="oline-tag"
                              style={{ backgroundColor: getStatusColor(items.contact_user.visible_status) }} />{getAvailabiltyStatusText(items.contact_user.visible_status)}
                          </span>
                          <span>
                            {
                              items.privacy_setting && items.privacy_setting.length ?
                                (
                                  getPrivacySettingsRoomIamInOptions(items.privacy_setting.filter((x: any) => x.key === "show_room_i_am_in" && x.field_type_details === "checkbox")) == "0"
                                    ?
                                    <p className="left-menu-tooltip" title={items.contact_user.about}>
                                      {
                                        trimTo(items.contact_user.about, 20, true)
                                      }
                                    </p>
                                    :
                                    (
                                      (
                                        getPrivacySettingsRoomIamInOptions(items.privacy_setting.filter((x: any) => x.key === "show_room_i_am_in_options" && x.field_type_details === "radio")) == "1" && items.is_in_contact
                                      ) || getPrivacySettingsRoomIamInOptions(items.privacy_setting.filter((x: any) => x.key === "show_room_i_am_in_options" && x.field_type_details === "radio")) == "2"
                                        ?
                                        <p className="left-menu-tooltip" title={items.first_room && items.first_room.room_details ? items.first_room.room_details.room_name : ""}>
                                          {
                                            trimTo(items.first_room && items.first_room.room_details ? items.first_room.room_details.room_name : "", 20, true)
                                          }
                                        </p>

                                        : null
                                    )
                                ) : null
                            }
                          </span>
                        </div>

                      </Link>

                      <a href="#" onClick={(e) => showAlert(e, items.contact_user_id)} className="submenu-close-btn" />
                    </li>
                  )) :
                  <li>
                    No Contact found
                  </li>
              }
            </ul>
          </li>
          <li>
            <a href="#" className="has-arrow waves-effect">
              <i className="sidebar-icon-3" />
              <span key="t-layouts">Online  {leftMenuItemDetails && leftMenuItemDetails.online_users && leftMenuItemDetails.online_users.length ? '(' + `${leftMenuItemDetails.online_users.length + 1}` + ')' : '(1)'}</span>
            </a>
            <ul className="sub-menu" aria-expanded="true">
              {/* Current user Static Data Added */}
              <li>
                {/* onContextMenu={(e) => onContextMenuClick(e, userSelector)} */}
                <Link to="#"
                  onContextMenu={(e) => onSelfContextMenuClick(e, userSelector)}
                  onDoubleClick={() => handleSendPm(userSelector ? userSelector.id : 0)}
                >
                  <span className="sub-menu-avatar">
                    {
                      userSelector && userSelector.avatar
                        && userSelector.avatar.thumb ?
                        <img src={userSelector.avatar.thumb} alt={userSelector.username} />
                        : (<span className="text-avatar">{getNameInitials(userSelector?.username)}</span>)
                    }
                  </span>
                  <div className="sub-menu-info"
                    style={{
                      color: getSubscriptionColor(userSelector && userSelector.is_subscribed ?
                        ({
                          ...userSelector,
                          subscription_info: userSelector.is_subscribed
                        })
                        : null)
                    }}
                  >
                    {userSelector?.username}
                    <span>
                      <i className="oline-tag"
                        style={{ backgroundColor: getStatusColor(userSelector?.visible_status) }} /> {getAvailabiltyStatusText(userSelector?.visible_status)}</span>
                  </div>
                </Link>
              </li>

              {
                leftMenuItemDetails && leftMenuItemDetails.online_users && leftMenuItemDetails.online_users.length ?
                  leftMenuItemDetails.online_users.map((items: any, index: number) => (
                    <li key={index}>
                      <Link to="#"
                        className={clsx({
                          'disable-link': items.is_block
                        })}
                        onContextMenu={(e) => onContextMenuClick(e, items)}
                        onDoubleClick={() => handleSendPm(items.contact_user.id)}
                      >
                        <span className="sub-menu-avatar">
                          {
                            items && items.contact_user && items.contact_user.avatar && getBooleanStatus(items.contact_user.avatar && items.contact_user.avatar.visible_avatar ? items.contact_user.avatar.visible_avatar : 0) && items.contact_user.avatar.thumb ?
                              <img src={items.contact_user.avatar.thumb} alt={items.contact_user.username} /> : (<span className="text-avatar">{getNameInitials(items.contact_user.username)}</span>)
                          }
                        </span>
                        <div className="sub-menu-info" style={{
                          color: getSubscriptionColor(items && items.contact_user && items.contact_user.is_subscribed ? {
                            ...items,
                            subscription_info: items.contact_user.is_subscribed
                          } : null)
                        }}>
                          {
                            items.customize_nickname && items.customize_nickname.nickname ? items.customize_nickname.nickname : items.contact_user.username
                          }
                          <span>
                            <i className="oline-tag"
                              style={{ backgroundColor: getStatusColor(items.contact_user.visible_status) }} /> {getAvailabiltyStatusText(items.contact_user.visible_status)}
                          </span>
                          <span>
                            {
                              items.privacy_setting && items.privacy_setting.length ?
                                (
                                  getPrivacySettingsRoomIamInOptions(items.privacy_setting.filter((x: any) => x.key === "show_room_i_am_in" && x.field_type_details === "checkbox")) == "0"
                                    ?
                                    <p className="left-menu-tooltip" title={items.contact_user.about}>
                                      {
                                        trimTo(items.contact_user.about, 20, true)
                                      }
                                    </p>
                                    :
                                    (
                                      (
                                        getPrivacySettingsRoomIamInOptions(items.privacy_setting.filter((x: any) => x.key === "show_room_i_am_in_options" && x.field_type_details === "radio")) == "1" && items.is_in_contact
                                      ) || getPrivacySettingsRoomIamInOptions(items.privacy_setting.filter((x: any) => x.key === "show_room_i_am_in_options" && x.field_type_details === "radio")) == "2"
                                        ?
                                        <p className="left-menu-tooltip" title={items.first_room && items.first_room.room_details ? items.first_room.room_details.room_name : ""}>
                                          {
                                            trimTo(items.first_room && items.first_room.room_details ? items.first_room.room_details.room_name : "", 20, true)
                                          }
                                        </p>

                                        : null
                                    )
                                ) : null
                            }
                          </span>
                        </div>
                      </Link>
                      {/* <a href="#" key="t-vertical" className={clsx({
                        'disable-link': items.is_block
                      })}>
                        <span className="sub-menu-avatar">
                          {
                            items && items.contact_user && items.contact_user.avatar && getBooleanStatus(items.contact_user.avatar && items.contact_user.avatar.visible_avatar ? items.contact_user.avatar.visible_avatar : 0) && items.contact_user.avatar.thumb ?
                              <img src={items.contact_user.avatar.thumb} alt={items.contact_user.username} /> : (<span className="text-avatar">{getNameInitials(items.contact_user.username)}</span>)
                          }
                        </span>
                        <div className="sub-menu-info">{items.customize_nickname && items.customize_nickname.nickname ? items.customize_nickname.nickname : items.contact_user.username} <span>
                          <i className="oline-tag" style={{ backgroundColor: getStatusColor(items.contact_user.visible_status) }} /> {getAvailabiltyStatusText(items.contact_user.visible_status)}</span></div>
                      </a> */}
                    </li>
                  )) : null
                // <li>
                //   No user found
                // </li>
              }
            </ul>
          </li>
          <li>
            <a href="#" className="has-arrow waves-effect">
              <i className="offline-icon-white" />
              <span key="t-layouts">Offline {leftMenuItemDetails && leftMenuItemDetails.offline_users && leftMenuItemDetails.offline_users.length ? '(' + leftMenuItemDetails.offline_users.length + ')' : ''}</span>
            </a>
            <ul className="sub-menu" aria-expanded="true">
              {
                leftMenuItemDetails && leftMenuItemDetails.offline_users && leftMenuItemDetails.offline_users.length ?
                  leftMenuItemDetails.offline_users.map((items: any, index: number) => (
                    <li key={index}>
                      <Link to="#"
                        className={clsx({
                          'disable-link': items.is_block
                        })}
                        onContextMenu={(e) => onContextMenuClick(e, items)}
                        onDoubleClick={() => handleSendPm(items.contact_user.id)}
                      >
                        <span className="sub-menu-avatar">
                          {
                            items && items.contact_user && items.contact_user.avatar && getBooleanStatus(items.contact_user.avatar && items.contact_user.avatar.visible_avatar ? items.contact_user.avatar.visible_avatar : 0) && items.contact_user.avatar.thumb ?
                              <img src={items.contact_user.avatar.thumb} alt={items.contact_user.username} /> : (<span className="text-avatar">{getNameInitials(items.contact_user.username)}</span>)
                          }
                        </span>
                        <div className="sub-menu-info" style={{
                          color: getSubscriptionColor(items && items.contact_user && items.contact_user.is_subscribed ? {
                            ...items,
                            subscription_info: items.contact_user.is_subscribed
                          } : null)
                        }}>
                          {items.customize_nickname && items.customize_nickname.nickname ? items.customize_nickname.nickname : items.contact_user.username}
                          <span>
                            <i className="offline-tag" />
                            {/* {getAvailabiltyStatusText(items.contact_user.visible_status)} */}
                            Offline
                          </span>
                          <span>
                            {
                              items.privacy_setting && items.privacy_setting.length ?
                                (
                                  getPrivacySettingsRoomIamInOptions(items.privacy_setting.filter((x: any) => x.key === "show_room_i_am_in" && x.field_type_details === "checkbox")) == "0"
                                    ?
                                    <p className="left-menu-tooltip" title={items.contact_user.about}>
                                      {
                                        trimTo(items.contact_user.about, 20, true)
                                      }
                                    </p>
                                    :
                                    (
                                      (
                                        getPrivacySettingsRoomIamInOptions(items.privacy_setting.filter((x: any) => x.key === "show_room_i_am_in_options" && x.field_type_details === "radio")) == "1" && items.is_in_contact
                                      ) || getPrivacySettingsRoomIamInOptions(items.privacy_setting.filter((x: any) => x.key === "show_room_i_am_in_options" && x.field_type_details === "radio")) == "2"
                                        ?
                                        <p className="left-menu-tooltip" title={items.first_room && items.first_room.room_details ? items.first_room.room_details.room_name : ""}>
                                          {
                                            trimTo(items.first_room && items.first_room.room_details ? items.first_room.room_details.room_name : "", 20, true)
                                          }
                                        </p>

                                        : null
                                    )
                                ) : null
                            }
                          </span>
                        </div>
                      </Link>
                    </li>
                  )) :
                  <li>
                    No user found
                  </li>
              }
            </ul>
          </li>
        </MetisMenu>
        <div className="profile-pic-check">
          <p className="online-user">
            {
              leftMenuItemDetails && leftMenuItemDetails.total_online_user
            } users online
          </p>
          <div className="custom-control custom-checkbox">
            <input type="checkbox"
              onChange={(e) => {
                toggleShowHideProfilePicture(e.target.checked)
              }}
              className="custom-control-input"
              id="customControlInline2"
              checked={
                userSelector
                  && userSelector.visible_option && userSelector.visible_option.length
                  ? getBooleanStatus(getValueFromArrayOfObject(userSelector.visible_option, "avatar_visible")) : false
              }
            />
            <label className="custom-control-label" htmlFor="customControlInline2">Show user's profile pictures</label>
          </div>
          <div className="build-version-label">
            <label>Build Version: <code className="build-version">{APP_VERSION}</code></label>
          </div>
        </div>
      </div>

      <LeftBarPersonContextMenu
        getParams={contextMenuOperationParams}
        profile={membersData}
        isLoginUsers={isLoginUser}
      />

      <StatusToggleMenu />

      {
        showProfileModal &&
        <ViewProfileModal
          onClose={onViewProfileModalClose}
          shouldShow={showProfileModal}
          addToContactList={() => { }}
          isAddedToContactList={true}
          userId={selectedUserId}
        />
      }

      {
        showStickerBuyModal ?
          <StickerBuyModal
            onClose={handleOnCloseSticker}
            shouldShow={showStickerBuyModal}
            byModalType={"giftSendStickerBuy"}  //ownStickerBuy or giftSendStickerBuy
            selectedContactList={selectedUser}
            type={''} // Make it blank
          /> : null
      }

      {
        showCustomizedNicknameModal ?
          <CustomizedNicknameModal
            onClose={customizedNicknameModalClose}
            onSuccess={() => { }}
            shouldShow={showCustomizedNicknameModal}
            fetchData={membersData}
          /> : null
      }

      {/*Global Socket loader */}
      <SocketLoader />

    </React.Fragment>
  )
}

export default Sidebar
