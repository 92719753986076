let apiBaseUrl = 'https://outrighttalk-backend.glohtesting.com/api/v1/';
let socketUrl = 'https://outrighttalk.glohtesting.com:3262';

let appStage = process.env.REACT_APP_STAGE

if (appStage == 'dev') {
  apiBaseUrl = 'https://outrighttalk-backend.glohtesting.com/api/v1/';
  socketUrl = 'https://outrighttalk.glohtesting.com:3262';
} else if (appStage == 'stage') {
  apiBaseUrl = 'https://outrighttalk-backend.glohtesting.com/api/v1/';
  socketUrl = 'https://outrighttalk.glohtesting.com:3262';
} else if (appStage == 'prod') {
  apiBaseUrl = 'https://outrighttalk-backend.glohtesting.com/api/v1/';
  socketUrl = 'https://outrighttalk.glohtesting.com:3262';
}

export const API_BASE_URL = apiBaseUrl
export const SOCKETURL = socketUrl