import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import clsx from 'clsx';

import { usePmWindowApi } from 'src/_common/hooks/actions/pmWindow/appPmWindowApiHook';
import { useAppPmWindowAction } from 'src/_common/hooks/actions/pmWindow/appPmWindowActionHook';
import { useAppActivePmWindowListSelector, useAppActivePmsRouteSelector } from 'src/_common/hooks/selectors/pmWindowSelector';
import { toast } from 'react-toastify';
import { CRYPTO_SECRET_KEY } from 'src/_config';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    swipeToSlide: true,
    autoplay: false,
};

export default function ActivePmWindowPopDown() {

    const [selectedSlider, setSelectedSlider] = useState(0);
    const pmWindowApi = usePmWindowApi()
    const pmWindowAction = useAppPmWindowAction()
    const activePmWindowSelector = useAppActivePmWindowListSelector()
    const history = useHistory()
    const location = useLocation()

    const { pmId } = useParams<any>();
    const pm_id: any = pmId ? parseInt(cryptr.decrypt(pmId)) : null;

    const handleCloseActivePmWindow = (e: any) => {
        e.preventDefault()
        pmWindowAction.activePmWindowPopDownHandler(false)
    }

    const handleActivePmWindowRedirect = (id: number) => {
        const userId = cryptr.encrypt(id)
        history.replace('')
        history.push(`pm/${userId}`)
    }

    const handleEnterPmWindow = (e: any, data: any) => {
        e.preventDefault();
        let pageUrl = history.location.pathname.split("/")
        if (pageUrl && pageUrl.length && pageUrl.includes('pm')) {
            if (pm_id && pm_id != data.id) {
                setSelectedSlider(data.id)                
                // let path = history.location.pathname.split('/').pop();
                // groupCategoryAction.emptyRoomDetails()
                pmWindowAction.activePmWindowPopDownHandler(false)
                pmWindowAction.fromRouteHandler(data.id)
                handleActivePmWindowRedirect(data.id)
                // const groupId = cryptr.encrypt(data.group_id)
                // const roomId = cryptr.encrypt(data.id)
                // // groupCategoryAction.roomMembersLargeViewMembersStreamData([])
                // history.replace('');
                // history.push(`${groupId}/${roomId}/room-details`);
            }
            else {
                pmWindowAction.activePmWindowPopDownHandler(false)
            }
        }
        else {
            setSelectedSlider(data.id)            
            // let path = history.location.pathname.split('/').pop();
            // groupCategoryAction.emptyRoomDetails()
            pmWindowAction.activePmWindowPopDownHandler(false)
            pmWindowAction.fromRouteHandler(data.id)
            handleActivePmWindowRedirect(data.id)
            // const groupId = cryptr.encrypt(data.group_id)
            // const roomId = cryptr.encrypt(data.id)
            // // groupCategoryAction.roomMembersLargeViewMembersStreamData([])
            // history.replace('');
            // history.push(`${groupId}/${roomId}/room-details`);
        }

    }

    const getPmAdminName = (users: any[]) => {
        let foundUsers = users.filter((x: any) => x.is_admin == 1)
        if (foundUsers && foundUsers.length) {
            return foundUsers[0].customize_nickname && foundUsers[0].customize_nickname.nickname ? foundUsers[0].customize_nickname.nickname : foundUsers[0].user_info.username
        } else {
            return 'NA';
        }
    }

    const renderSlides = () =>
        activePmWindowSelector && activePmWindowSelector.length && activePmWindowSelector.map((activePmWindow: any, index: any) => (
            <div key={index}>
                <div className="room-box" onClick={(e) => handleEnterPmWindow(e, activePmWindow)}>
                    {/* <a href="#" onClick={(e) => handleExitRoom(e, activePmWindow.id)} className="exit-room">
                        <img src="/img/exit-small-icon.png" alt="" />
                    </a> */}
                    {
                        activePmWindow.pm_type === 'group' ?
                            <img src="/img/room-img.jpg" alt="group_image" /> :
                            <img src="/img/room-img.jpg" alt="single_image" />
                    }
                    {/* <img src={activePmWindow.room_picture && activePmWindow.room_picture.thumb ? activePmWindow.room_picture.thumb : "/img/room-img.jpg"} alt={activePmWindow.room_name} /> */}
                    {/* <img src="/img/room-img01.jpg" alt={activePmWindow.room_name} /> */}
                    <div className="room-box-name">
                        {
                            getPmAdminName(activePmWindow.users)
                        }
                    </div>
                </div>
            </div>
        ));

    //Get active pms
    const getActivePmWindows = () => {
        pmWindowApi.callGetActivePmWindows((message: string, resp: any) => {
        }, (message: string) => {
        })
    }

    useEffect(() => {
        getActivePmWindows()
    }, [])

    return (
        <React.Fragment>
            <div className="active-room-wrapper">
                <a href="#" onClick={(e) => handleCloseActivePmWindow(e)} className="close-box">
                    <img src="/img/close-icon.png" alt="close" /></a>
                <div className="room-list">
                    {
                        activePmWindowSelector && activePmWindowSelector.length ?
                            <Slider {...settings}>
                                {
                                    renderSlides()
                                }
                            </Slider> :
                            <div className="no-active-pm-container">
                                {/* <img src="/img/room-img.jpg" alt="no-room" />
                            <div className="room-box-name">No Active PM</div> */}
                                <p className="no-active-pm">No Active PM</p>
                            </div>
                    }
                </div>
            </div>
        </React.Fragment >
    )
}
