import { CmsContentDetails, ContactListOnlineOffline } from 'src/_common/interfaces/ApiReqRes';
import { SAGA_ACTIONS } from 'src/_config'
import { useApiCall } from '../common/appApiCallHook'

export function useCommonApi() {

  const callApi = useApiCall()

  const getGenderList = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.GENDER_LIST, null, onSuccess, onError);
  }
  const getCountryList = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.COUNTRY_LIST, null, onSuccess, onError);
  }
  const getSecretQuestion = (onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.SECRET_QUESTION_LIST, null, onSuccess, onError);
  }
  const getOnlineOfflineContactList = (data: ContactListOnlineOffline, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.ONLINE_OFFLINE_CONTACT_LIST, data, onSuccess, onError);
  }

  const getStickerCategories = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.CHAT_STICKER_CATEGORIES, data, onSuccess, onError);
  }

  const getAllStickerCategorywise = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.GET_CATEGORYWISE_ALL_STICKER, data, onSuccess, onError);
  }

  const stickerBuy = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.STICKER_BUY, data, onSuccess, onError);
  }

  const stickerOwn = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.STICKER_OWN, data, onSuccess, onError);
  }

  const stickerGiftpurchase = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.STICKER_GIFT_PURCHASE, data, onSuccess, onError);
  }

  const cmsContentDetails = (data: CmsContentDetails, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMMON.CMS, data, onSuccess, onError);
  }

  return {
    callGetGenderList: getGenderList,
    callGetCountryList: getCountryList,
    callGetSecretQuestion: getSecretQuestion,
    callOnlineOfflineCOntactList: getOnlineOfflineContactList,
    callGetStickerCategories: getStickerCategories,
    callGetAllStickerCategorywise: getAllStickerCategorywise,
    callStickerBuy: stickerBuy,
    callStickerOwn: stickerOwn,
    callStickerGiftpurchase: stickerGiftpurchase,
    callCmsContentDetails: cmsContentDetails,
  }
}