import { call } from 'redux-saga/effects';
import { API_URL } from '../_config';
import { CallApi } from './api/callApi';

export function* getGenderList(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.GENDER_LIST, data, false);

    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getCountryList(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.COUNTRY_LIST, data, false);

    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getSecretQuestion(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.SECRET_QUESTION_LIST, data, false);

    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getOnlineOfflineCOntactList(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.ONLINE_OFFLINE_CONTACT_LIST, data, true);

    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getStickerCategories(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.CHAT_STICKER_CATEGORIES, data, true);

    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* getAllStickerCategorywise(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.GET_CATEGORYWISE_ALL_STICKER, data, true);

    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* buySticker(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.STICKER_BUY, data, true);

    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* myOwnSticker(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.GET, API_URL.COMMON.STICKER_OWN, data, true);

    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* sendStickerGift(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.STICKER_GIFT_PURCHASE, data, true);

    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}

export function* cmsContent(action: any): any {
  try {
    const data = action.payload;
    const resp = yield call(CallApi.POST, API_URL.COMMON.CMS, data, true);

    if (resp.status >= 200 && resp.status < 300 && resp.data && resp.data) {

      action && action.callbackSuccess && action.callbackSuccess(resp.data);
    } else {
      action && action.callbackError && action.callbackError(resp.data);
    }
  } catch (e) {
    action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
  }
}