import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useParams } from 'react-router'
import { Spinner } from 'react-bootstrap'
import { useAppRoomListCategoryWiseSelector } from '../hooks/selectors/groupCategorySelector'
import LockWordModal from 'src/components/commonModals/lockWordModal/lockWordModal';
import AdminCodeModal from 'src/components/commonModals/adminCodeModal/adminCodeModal';
import { useGroupCategoryApi } from '../hooks/actions/groupCategory/appGroupCategoryApiHook';
import SweetAlert from 'react-bootstrap-sweetalert';
import { CRYPTO_SECRET_KEY, URLS } from 'src/_config';
import { toast } from 'react-toastify';
const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

const joinRoomGauard = (Component: React.ComponentType) => {
  function JoinRoomHoc(props: any) {

    const groupCategoryApi = useGroupCategoryApi()
    const { groupId } = useParams<any>();
    const { roomId } = useParams<any>();
    const history = useHistory()
    const rommListCategoryWise = useAppRoomListCategoryWiseSelector()
    const [lockRoomModal, setLockRoomModal] = useState(false)
    const [adminCodeModal, setAdminCodeModal] = useState(false)
    const [showLockLoader, setShowLockLoader] = useState(true)
    const [isFirstTimeLockWordRun, setIsFirstTimeLockWordRun] = useState<boolean>(false)
    const [alert, setAlert] = useState<any>(null);

    const closeLockWindowModal = (success: any) => {
      setLockRoomModal(false)
      if (success) {
        //call join room Api here
        //after success full joinApi room called call getRoomList Api for admin or headAdmin check
        joinRoom()
      } else {
        history.goBack()
      }
    }

    const closeAdminCodeModal = (success: any) => {
      setAdminCodeModal(false)
      if (success) {
        setShowLockLoader(false)
      } else {
        history.goBack()
      }
    }

    const hideAlert = () => {
      setAlert(null);
    }

    const handleMultipleJoinRoomCancel = () => {
      setAlert(null);
      history.replace("")
      history.push(URLS.USER.DASHBOARD)
    }

    const handleShowAlert = (exit_room: number) => {
      setAlert(
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Ok"
          cancelBtnText="Cancel"
          cancelBtnBsStyle="success"
          confirmBtnBsStyle="danger"
          allowEscape={false}
          closeOnClickOutside={false}
          title="Alert"
          onConfirm={() => handleFinalJoinRoom(exit_room)}
          onCancel={handleMultipleJoinRoomCancel}
          focusCancelBtn={true}
        >
          You have a basic nickname, you cannot be in more than one voice room simultaneously, you will exit the current room automatically. Do you want to proceed?
        </SweetAlert>
      );
    }

    const joinRoom = () => {
      const params = {
        room_id: parseInt(cryptr.decrypt(roomId)),
      };
      groupCategoryApi.callJoinSimultaneouslyRoom(params, (message: string, resp: any) => {
        if (resp) {
          handleFinalJoinRoom(0)
        }
      }, (message: string) => {
        // toast.error(message)
        // history.replace("")
        // history.push(URLS.USER.DASHBOARD)
        handleShowAlert(1)
      })
    }

    const handleFinalJoinRoom = (exit_room: number) => {
      const params = {
        room_id: parseInt(cryptr.decrypt(roomId)),
        exit_room
      };
      groupCategoryApi.callJoinRoom(params, (message: string, resp: any) => {
        setShowLockLoader(false)
        getRoomList()
        hideAlert()
      }, (message: string) => {
        toast.error(message)
        history.replace("")
        history.push(URLS.USER.DASHBOARD)
      })
    }



    useEffect(() => {
      getRoomList()
    }, [groupId])


    const getRoomList = () => {
      const params = {
        group_id: parseInt(cryptr.decrypt(groupId)),
      };
      groupCategoryApi.callGetRoomListCategoryWise(params, (message: string, resp: any) => {

      }, (message: string) => {
      })
    }

    useEffect(() => {
      if (rommListCategoryWise && rommListCategoryWise.list && rommListCategoryWise.list.length && roomId && !isFirstTimeLockWordRun) {
        let routeRoom = rommListCategoryWise && rommListCategoryWise.list && rommListCategoryWise.list.length ? rommListCategoryWise.list.filter(x => x.id == parseInt(cryptr.decrypt(roomId))) : null;
        if (routeRoom && routeRoom.length) {
          if (routeRoom[0].locked_room && routeRoom[0].join_status == null) { //when room is locked and not user notjoin or exit room then this segment called
            //open lockWord Popup Here
            setLockRoomModal(true)
          }
          else if (routeRoom[0].locked_room && routeRoom[0].join_status && [1, 2].includes(routeRoom[0].join_status.is_admin)) {//room islocked and user is admin or head admin then admin password modal appear
            setAdminCodeModal(true)
          } else if (!routeRoom[0].locked_room && routeRoom[0].join_status == null) {
            joinRoom()
            setIsFirstTimeLockWordRun(true)
          }
          else {
            setShowLockLoader(false)
          }
        }
      }
    }, [rommListCategoryWise, roomId])




    return (
      <React.Fragment>
        {alert}
        {showLockLoader ?
          <div className='lock-loader-container'>
            <div className='lock-loader-base'>
              <Spinner animation="border" variant="info" />
            </div>
          </div>
          :
          <Component {...props} />
        }
        <LockWordModal
          onClose={closeLockWindowModal}
          shouldShow={lockRoomModal}
        />
        <AdminCodeModal
          onClose={closeAdminCodeModal}
          shouldShow={adminCodeModal}
        />
      </React.Fragment>
    )
  }

  return JoinRoomHoc
}
export default joinRoomGauard
