import React from 'react';
import { Menu, Item, Separator, Submenu } from 'react-contexify';
import { useAppGroupCategoryAction } from 'src/_common/hooks/actions/groupCategory/appGroupCategoryActionHook';
import { CRYPTO_SECRET_KEY, getBooleanStatus, MENU_OPERATIONS } from 'src/_config';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useGroupCategoryApi } from 'src/_common/hooks/actions/groupCategory/appGroupCategoryApiHook';
import { toast } from 'react-toastify';
import { useAppRoomDetailsSelector } from 'src/_common/hooks/selectors/groupCategorySelector';
import { useAppPmWindowDetails } from 'src/_common/hooks/selectors/pmWindowSelector';
import { usePmWindowApi } from 'src/_common/hooks/actions/pmWindow/appPmWindowApiHook';
import { useAppPmWindowAction } from 'src/_common/hooks/actions/pmWindow/appPmWindowActionHook';
const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

const SettingContextMenu = (props: any) => {

    const { groupId, roomId } = useParams<any>();
    const { pmId } = useParams<any>();
    // const pm_id:any = parseInt(cryptr.decrypt(pmId));
    const groupCategoryAction = useAppGroupCategoryAction()
    const pmWindowAction = useAppPmWindowAction()
    const pmWindowDetailsSelector = useAppPmWindowDetails()
    const pmWindowApi = usePmWindowApi()
    const groupCategoryApi = useGroupCategoryApi()
    const roomDetailsSelector = useAppRoomDetailsSelector()
    const history = useHistory()

    const handleItemClick = (e: any) => {
    }

    const getRoomDetails = () => {
        const params = {
            room_id: parseInt(cryptr.decrypt(roomId))
        };
        groupCategoryApi.callGetRoomDetails(params, (message: string, resp: any) => {
            if (resp && resp.list && resp.list.length) { }
        }, (message: string) => {
            // toast.error(message)
        })
    }

    //Room User Settings menu
    const handleRoomSettings = (operation: string) => {

        const params = {
            room_id: parseInt(cryptr.decrypt(roomId)),
            key_name: operation,
            key_value: roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings[operation] ? 0 : 1
        }

        groupCategoryApi.callChangeUserRoomSettings(params, (message: string, resp: any) => {
            if (resp) {

                //Call only if operation is timestamp
                if (operation === MENU_OPERATIONS.TIMESTAMP) {
                    groupCategoryAction.roomChatTimestampToogle(getBooleanStatus(resp[operation]))
                }

                //Call only if operation is push to talk & lock mic
                if (operation === MENU_OPERATIONS.PUSH_TO_TALK || operation === MENU_OPERATIONS.LOCK_MIC) {
                    groupCategoryAction.resetNormalUserMicHandleIsChanged(true)
                }

                getRoomDetails()
            }
        }, (message: string) => {
            toast.error(message)
        })
    }

    const handleSaveAsDefaultRoomSetting = () => {
        const params = {
            room_id: parseInt(cryptr.decrypt(roomId))
        };
        groupCategoryApi.callRoomSaveDefaultSetting(params, (message: string, resp: any) => {
            if (resp) {

            }
        }, (message: string) => {
            // toast.error(message)
        })
    }

    const handleResetDefaultSetting = () => {
        const params = {
            room_id: parseInt(cryptr.decrypt(roomId))
        };
        groupCategoryApi.callRoomResetDefaultSetting(params, (message: string, resp: any) => {
            if (resp) {
                getRoomDetails()
            }
        }, (message: string) => {
            // toast.error(message)
        })
    }

    //Pm setting related Api call

    const getPmWindowDetails = () => {
        let params = {
            pm_id: parseInt(cryptr.decrypt(pmId))
        }
        pmWindowApi.callGetPmsDetails(params, (message: string, resp: any) => {

        }, (message: string) => {
            toast.error(message)
        })
    }

    const handlePmSetting = (operation: string) => {
        const params = {
            pm_id: parseInt(cryptr.decrypt(pmId)),
            key_name: operation,
            key_value: pmWindowDetailsSelector && pmWindowDetailsSelector.pm_settings && pmWindowDetailsSelector.pm_settings[operation] ? 0 : 1
        }
        pmWindowApi.callPmHeaderMenuSettingActionUpdate(params, (message: string, resp: any) => {
            if (resp) {
                
                //Call only if operation is timestamp
                if (operation === MENU_OPERATIONS.TIMESTAMP) {
                    pmWindowAction.pmChatTimestampToogle(getBooleanStatus(resp[operation]))
                }

                getPmWindowDetails()
            }

        }, (message: string) => {

        })
    }


    const handleSaveAsDefaultPmSetting = () => {
        const params = {
            pm_id: parseInt(cryptr.decrypt(pmId))
        };
        pmWindowApi.callPmHeaderMenuSaveDefaultSetting(params, (message: string, resp: any) => {
            if (resp) {
                getPmWindowDetails()
            }

        }, (message: string) => {
            // toast.error(message)
        })
    }

    const handleResetDefaultPmSetting = () => {
        const params = {
            pm_id: parseInt(cryptr.decrypt(pmId))
        };
        pmWindowApi.callPmHeaderMenuResetDefaultSetting(params, (message: string, resp: any) => {
            if (resp) {
                getPmWindowDetails()
            }

        }, (message: string) => {
            // toast.error(message)
        })
    }


    return (
        <React.Fragment>
            <Menu id='room_header_menu_setting_id' className="header-click-menu">
                <Item
                    onClick={(event) => handleRoomSettings(MENU_OPERATIONS.AUTOSCROLL_TEXT)}>
                    {
                        roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings[MENU_OPERATIONS.AUTOSCROLL_TEXT] ?
                            <i className="fa fa-check" aria-hidden="true"></i> : null
                    }
                    Autoscroll Text
                </Item>
                <Separator />
                <Item
                    onClick={(event) => handleRoomSettings(MENU_OPERATIONS.SHOW_INCOMING_TEXT_WITH_FORMAT)}>
                    {
                        roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings[MENU_OPERATIONS.SHOW_INCOMING_TEXT_WITH_FORMAT] ?
                            <i className="fa fa-check" aria-hidden="true"></i> : null
                    }
                    Show Incoming Text with Format</Item>
                <Separator />
                <Item
                    onClick={(event) => handleRoomSettings(MENU_OPERATIONS.TIMESTAMP)}>
                    {
                        roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings[MENU_OPERATIONS.TIMESTAMP] ?
                            <i className="fa fa-check" aria-hidden="true"></i> : null
                    }
                    Timestamp
                </Item>

                <Item
                    onClick={(event) => handleRoomSettings(MENU_OPERATIONS.SORT_NICKNAME_ALPHABETICALLY)}>
                    {
                        roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings[MENU_OPERATIONS.SORT_NICKNAME_ALPHABETICALLY] ?
                            <i className="fa fa-check" aria-hidden="true"></i> : null
                    }
                    Sort Nicknames Alphabetically
                </Item>
                <Separator />
                <Item
                    onClick={(event) => handleRoomSettings(MENU_OPERATIONS.DISABLE_DIG_SOUND)}>
                    {
                        roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings[MENU_OPERATIONS.DISABLE_DIG_SOUND] ?
                            <i className="fa fa-check" aria-hidden="true"></i> : null
                    }
                    Disable Dig Sound
                </Item>

                <Submenu label="Notify Me When User">
                    <Item
                        onClick={(event) => handleRoomSettings(MENU_OPERATIONS.NOTIFY_USER_JOIN_ROOM)}>
                        {
                            roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings[MENU_OPERATIONS.NOTIFY_USER_JOIN_ROOM] ?
                                <i className="fa fa-check" aria-hidden="true"></i> : null
                        }
                        Joins a room
                    </Item>
                    <Item
                        onClick={(event) => handleRoomSettings(MENU_OPERATIONS.NOTIFY_EXIT_ROOM)}>
                        {
                            roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings[MENU_OPERATIONS.NOTIFY_EXIT_ROOM] ?
                                <i className="fa fa-check" aria-hidden="true"></i> : null
                        }
                        Exits room
                    </Item>
                    <Item
                        onClick={(event) => handleRoomSettings(MENU_OPERATIONS.NOTIFY_START_WEBCAM)}>
                        {
                            roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings[MENU_OPERATIONS.NOTIFY_START_WEBCAM] ?
                                <i className="fa fa-check" aria-hidden="true"></i> : null
                        }
                        Starts Webcam
                    </Item>
                    <Item
                        onClick={(event) => handleRoomSettings(MENU_OPERATIONS.NOTIFY_STOP_WEBCAM)}>
                        {
                            roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings[MENU_OPERATIONS.NOTIFY_STOP_WEBCAM] ?
                                <i className="fa fa-check" aria-hidden="true"></i> : null
                        }
                        Stops Webcam
                    </Item>
                </Submenu>
                {/* <Item onClick={(event) => handleItemClick(event)}>Font</Item> */}
                <Item
                    onClick={(event) => handleRoomSettings(MENU_OPERATIONS.CHANGE_ROOM_SCREEN)}>
                    {
                        roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings[MENU_OPERATIONS.CHANGE_ROOM_SCREEN] ?
                            <i className="fa fa-check" aria-hidden="true"></i> : null
                    }
                    Change Room Skin
                </Item>
                <Item
                    onClick={(event) => handleRoomSettings(MENU_OPERATIONS.MUTE_INCOMING_SOUND)}>
                    {
                        roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings[MENU_OPERATIONS.MUTE_INCOMING_SOUND] ?
                            <i className="fa fa-check" aria-hidden="true"></i> : null
                    }
                    Mute Incoming Sound
                </Item>
                <Item onClick={() => handleSaveAsDefaultRoomSetting()}>Save as Default Room Settings</Item>
                <Item onClick={() => handleResetDefaultSetting()}>Reset Default Settings</Item>
            </Menu>

            <Menu id='pm_header_settings_id' className="header-click-menu">


                <Item onClick={(event) => handlePmSetting('autoscrool_text')}>
                    {pmWindowDetailsSelector && pmWindowDetailsSelector.pm_settings && pmWindowDetailsSelector.pm_settings.autoscrool_text ?
                        <i className="fa fa-check" aria-hidden="true"></i> : null
                    }
                    Autoscroll Text Chat
                </Item>

                <Item onClick={(event) => handlePmSetting(MENU_OPERATIONS.TIMESTAMP)}>
                    {pmWindowDetailsSelector && pmWindowDetailsSelector.pm_settings && pmWindowDetailsSelector.pm_settings.timestamp ?
                        <i className="fa fa-check" aria-hidden="true"></i> : null
                    }
                    Timestamp
                </Item>

                <Item onClick={(event) => handlePmSetting('disable_dig_sound')}>
                    {pmWindowDetailsSelector && pmWindowDetailsSelector.pm_settings && pmWindowDetailsSelector.pm_settings.disable_dig_sound ?
                        <i className="fa fa-check" aria-hidden="true"></i> : null
                    }
                    Disable Dig Sound
                </Item>

                <Separator />
                <Item onClick={(event) => handleItemClick(event)}>Font</Item>
                <Separator />
                <Item onClick={(event) => handleItemClick(event)}>PM Skins</Item>
                <Separator />
                <Item onClick={() => handleSaveAsDefaultPmSetting()}>Save as Default PM Settings</Item>
                <Item onClick={() => handleResetDefaultPmSetting()}>Reset Default PM Settings</Item>
            </Menu>
        </React.Fragment>

    );
};

export default SettingContextMenu;