import React, { useEffect, useState } from 'react'
import { useAppInstanceInvitedUsers } from 'src/_common/hooks/selectors/notificationSelector'
import { useAppNotificationAction } from 'src/_common/hooks/actions/notification/appNotificationActionHook';
import { useNotificationApi } from 'src/_common/hooks/actions/notification/appNotificationApiHook';
import { useGroupCategoryApi } from 'src/_common/hooks/actions/groupCategory/appGroupCategoryApiHook';
import { toast } from 'react-toastify';
import moment from 'moment';
import { CHAT_DATE_TIME_FORMAT, CRYPTO_SECRET_KEY, getBooleanStatus, getNameInitials, NOTIFICATION_TYPE } from 'src/_config';
import { useHistory } from 'react-router';
import { RemoveSingleNotification } from 'src/_common/interfaces/ApiReqRes';
import { usePmWindowApi } from 'src/_common/hooks/actions/pmWindow/appPmWindowApiHook';
import { useAppPmWindowAction } from 'src/_common/hooks/actions/pmWindow/appPmWindowActionHook';
const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

export default function Notification() {

    const history = useHistory()
    const notificationAction = useAppNotificationAction()
    const pmWindowAction = useAppPmWindowAction()
    const notificationAPi = useNotificationApi()
    const groupCategoryApi = useGroupCategoryApi()
    const pmWindowApi = usePmWindowApi()
    const notificationSelector = useAppInstanceInvitedUsers()
    const [showHideNotificationPanel, setShowHideNotificationPanel] = useState<boolean>(false)

    const handleRemoveSingleNotification = (e: any, id: number) => {
        if (e) {
            e.preventDefault()
        }
        const params: RemoveSingleNotification = {
            record_id: id
        }
        notificationAPi.callRemoveSingleNotification(params, (message: string, resp: any) => {
            if (resp) {
                notificationAction.removeSingleNotification(id)
            }
        }, (message: string) => {
            toast.error(message)
        })
    }

    const getAllNotifications = () => {
        notificationAPi.callGetALlNotifications((message: string, resp: any) => {
            if (resp) { }
        }, (message: string) => {
            toast.error(message)
        })
    }

    const handleAcceptJoinInvitation = (notificationId: number, g_id: number, r_id: number) => {
        const groupId = cryptr.encrypt(g_id)
        const roomId = cryptr.encrypt(r_id)
        history.replace('')
        history.push(`${groupId}/${roomId}/room-details`)
    }

    const handleNotificationPanelToggle = () => {
        setShowHideNotificationPanel(!showHideNotificationPanel)
    }

    const handleAcceptRejectPmAudioVideoNotification = (notificationId: number, entityId: number, type: string) => {
        if (type === 'accept') {
            console.log("Call Accept");

            let params = {
                pm_id: entityId,
                type: type
            }
            pmWindowApi.callPmCallAcceptReject(params, (message: string, resp: any) => {
                if (resp) {
                    // handleRemoveSingleNotification(null, notificationId)
                    notificationAction.removeSingleNotification(notificationId)
                    const pmId = cryptr.encrypt(entityId)
                    history.replace('')
                    history.push(`pm/${pmId}`)
                }
            }, (message: string) => {
                toast.error(message)
            })

        } else {
            console.log("Call Reject");
            let params = {
                pm_id: entityId,
                type: type
            }
            pmWindowApi.callPmCallAcceptReject(params, (message: string, resp: any) => {
                if (resp) {
                    // handleRemoveSingleNotification(null, notificationId)
                    notificationAction.removeSingleNotification(notificationId)
                }
            }, (message: string) => {
                toast.error(message)
            })
            // handleRemoveSingleNotification(null, notificationId)
        }
    }

    const handleRedirectToPm = (e: any, entityId: number) => {
        e && e.preventDefault()
        const params = {
            pm_id: entityId
        }
        pmWindowApi.callReadPm(params, (message: string, resp: any) => {
            if (resp) {
                const pmId = cryptr.encrypt(entityId)
                pmWindowAction.fromRouteHandler(entityId)
                history.replace('')
                history.push(`pm/${pmId}`)
            } else {
                toast.error(message)
            }
        }, (message: string, resp: any) => {
            toast.error(message)
        })
    }

    useEffect(() => {
        getAllNotifications()
    }, [])


    return (
        <React.Fragment>
            <div>
                <button type="button" className="btn header-item noti-icon waves-effect"
                    onClick={handleNotificationPanelToggle}
                >
                    <i className="bx bx-bell bx-tada"></i>
                    <span className="badge badge-danger badge-pill">
                        {
                            notificationSelector && notificationSelector.length ? notificationSelector.length : null
                        }
                    </span>
                </button>
                {
                    showHideNotificationPanel ?
                        <div className="notificattion_bar">
                            {
                                notificationSelector && notificationSelector.length ?
                                    notificationSelector.map((x: any, index: number) => (
                                        <div className="all_notify" key={index}>
                                            <div className="close-sec" onClick={(e) => handleRemoveSingleNotification(null, x.id)}>
                                                <i className="far fa-times-circle"></i>
                                            </div>
                                            <div className="inner_notify">
                                                {/* <img className="mr-3 rounded-circle avatar-xs" src="https://outrighttalkbackend.s3.us-west-2.amazonaws.com/assets/u/1631118932965650600.jpg" alt="sunhax"></img> */}
                                                <div className="media-body">
                                                    <h6 className="mt-0 mb-1">
                                                        {/* {x.message} */}
                                                        {
                                                            NOTIFICATION_TYPE.PM_NOTIFICATION === x.type ?
                                                                <>
                                                                    <a href="#" onClick={(e) => handleRedirectToPm(e, x.entity_id)}>
                                                                        {
                                                                            x.message
                                                                        }
                                                                    </a>
                                                                </> :
                                                                x.message
                                                        }
                                                    </h6>
                                                    <div className="font-size-12 text-muted">
                                                        <p className="mb-0">
                                                            <i className="mdi mdi-clock-outline"></i>
                                                            <span>
                                                                {
                                                                    moment(x.formated_date, 'DD-MMMM-YYYY hh:mm a').format(CHAT_DATE_TIME_FORMAT.DISPLAY_DATE_WITH_TIME)
                                                                }
                                                            </span>
                                                        </p>
                                                    </div>
                                                    {
                                                        NOTIFICATION_TYPE.INVITE === x.type ?
                                                            <div className="btns-two-wrap">
                                                                <button type="button"
                                                                    className="btn btn-success btn-sm"
                                                                    onClick={() => handleAcceptJoinInvitation(x.id, x.group_id, x.entity_id)}
                                                                >
                                                                    Accept
                                                                </button>
                                                                <button type="button"
                                                                    className="btn btn-danger btn-sm"
                                                                    onClick={() => handleRemoveSingleNotification(null, x.id)}
                                                                >
                                                                    Reject
                                                                </button>
                                                            </div>
                                                            : null
                                                    }

                                                    {
                                                        NOTIFICATION_TYPE.PM_AUDIO_VIDEO_NOTIFICATION === x.type ?
                                                            <div className="btns-two-wrap">
                                                                <button type="button"
                                                                    className="btn btn-success btn-sm"
                                                                    onClick={() => handleAcceptRejectPmAudioVideoNotification(x.id, x.entity_id, 'accept')}
                                                                >
                                                                    Accept
                                                                </button>
                                                                <button type="button"
                                                                    className="btn btn-danger btn-sm"
                                                                    onClick={() => handleAcceptRejectPmAudioVideoNotification(x.id, x.entity_id, 'reject')}
                                                                >
                                                                    Reject
                                                                </button>
                                                            </div>
                                                            : null
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    )) :
                                    <div className="notificattion_bar">
                                        <div className="all_notify">
                                            <div className="inner_notify">
                                                <div className="media-body">
                                                    No notification found
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div> : null
                }
            </div>
        </React.Fragment>
    )
}
