import React from 'react';
import { Menu, Item, Separator, Submenu } from 'react-contexify';
import { useAppGroupCategoryAction } from 'src/_common/hooks/actions/groupCategory/appGroupCategoryActionHook';
import { CRYPTO_SECRET_KEY, MENU_OPERATIONS, HEADER_MENU_SELECTION_TYPE } from 'src/_config';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useAppChatDataSelectDeselect } from 'src/_common/hooks/selectors/groupCategorySelector';
import { useAppPmChatDataSelectDeselect } from 'src/_common/hooks/selectors/pmWindowSelector';
import { useAppPmWindowAction } from 'src/_common/hooks/actions/pmWindow/appPmWindowActionHook';
const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

const EditContextMenu = (props: any) => {

    const { groupId, roomId } = useParams<any>();
    const groupCategoryAction = useAppGroupCategoryAction()
    const pmWindowAction = useAppPmWindowAction()
    const chatDataSelectDeselectSelector = useAppChatDataSelectDeselect()
    const pmChatDataSelectDeselectSelector = useAppPmChatDataSelectDeselect()
    const history = useHistory()

    const handleItemClick = (e: any, type?: string) => {
        switch (type) {
            case HEADER_MENU_SELECTION_TYPE.SELECT:
                groupCategoryAction.chatDataSelectDeselect(HEADER_MENU_SELECTION_TYPE.SELECT)
                break;
            case HEADER_MENU_SELECTION_TYPE.DESELECT:
                groupCategoryAction.chatDataSelectDeselect(null)
                break;
            case HEADER_MENU_SELECTION_TYPE.SELECT_ALL:
                groupCategoryAction.chatDataSelectDeselect(HEADER_MENU_SELECTION_TYPE.SELECT_ALL)
                break;
            case HEADER_MENU_SELECTION_TYPE.DESELECT_ALL:
                groupCategoryAction.chatDataSelectDeselect(HEADER_MENU_SELECTION_TYPE.DESELECT_ALL)
                break;
            case HEADER_MENU_SELECTION_TYPE.COPY:
                groupCategoryAction.chatDataSelectDeselect(HEADER_MENU_SELECTION_TYPE.COPY)
                break;
            case HEADER_MENU_SELECTION_TYPE.PASTE:
                groupCategoryAction.chatDataSelectDeselect(HEADER_MENU_SELECTION_TYPE.PASTE)
                break;
            default:
                break;
        }
    }

    const handlePmItemClick = (e: any, type?: string) => {
        switch (type) {
            case HEADER_MENU_SELECTION_TYPE.SELECT:
                pmWindowAction.pmWindowChatDataSelectDeselect(HEADER_MENU_SELECTION_TYPE.SELECT)
                break;
            case HEADER_MENU_SELECTION_TYPE.DESELECT:
                pmWindowAction.pmWindowChatDataSelectDeselect(null)
                break;
            case HEADER_MENU_SELECTION_TYPE.SELECT_ALL:
                pmWindowAction.pmWindowChatDataSelectDeselect(HEADER_MENU_SELECTION_TYPE.SELECT_ALL)
                break;
            case HEADER_MENU_SELECTION_TYPE.DESELECT_ALL:
                pmWindowAction.pmWindowChatDataSelectDeselect(HEADER_MENU_SELECTION_TYPE.DESELECT_ALL)
                break;
            case HEADER_MENU_SELECTION_TYPE.COPY:
                pmWindowAction.pmWindowChatDataSelectDeselect(HEADER_MENU_SELECTION_TYPE.COPY)
                break;
            case HEADER_MENU_SELECTION_TYPE.PASTE:
                pmWindowAction.pmWindowChatDataSelectDeselect(HEADER_MENU_SELECTION_TYPE.PASTE)
                break;
            default:
                break;
        }
    }

    return (
        <React.Fragment>
            <Menu id='room_header_menu_edit_id' className="header-click-menu">
                {
                    chatDataSelectDeselectSelector && chatDataSelectDeselectSelector === HEADER_MENU_SELECTION_TYPE.SELECT ?
                        <Item onClick={(event) => handleItemClick(event, HEADER_MENU_SELECTION_TYPE.DESELECT)}>
                            <i className="fa fa-check" aria-hidden="true"></i> Select
                        </Item> :
                        <Item onClick={(event) => handleItemClick(event, HEADER_MENU_SELECTION_TYPE.SELECT)}>
                            Select
                        </Item>
                }
                {
                    chatDataSelectDeselectSelector && chatDataSelectDeselectSelector === HEADER_MENU_SELECTION_TYPE.SELECT_ALL ?
                        <Item onClick={(event) => handleItemClick(event, HEADER_MENU_SELECTION_TYPE.DESELECT_ALL)}>
                            <i className="fa fa-check" aria-hidden="true"></i> SelectAll
                        </Item> :
                        <Item onClick={(event) => handleItemClick(event, HEADER_MENU_SELECTION_TYPE.SELECT_ALL)}>SelectAll</Item>
                }

                <Item onClick={(event) => handleItemClick(event, HEADER_MENU_SELECTION_TYPE.COPY)}>Copy</Item>
                <Item onClick={(event) => handleItemClick(event, HEADER_MENU_SELECTION_TYPE.PASTE)}>Paste</Item>
                <Separator />
                <Item onClick={(event) => handleItemClick(event)}>Print Screen an Area</Item>
            </Menu>

            <Menu id='pm_header_edit_id' className="header-click-menu">
            {
                    pmChatDataSelectDeselectSelector && pmChatDataSelectDeselectSelector === HEADER_MENU_SELECTION_TYPE.SELECT ?
                        <Item onClick={(event) => handlePmItemClick(event, HEADER_MENU_SELECTION_TYPE.DESELECT)}>
                            <i className="fa fa-check" aria-hidden="true"></i> Select
                        </Item> :
                        <Item onClick={(event) => handlePmItemClick(event, HEADER_MENU_SELECTION_TYPE.SELECT)}>
                            Select
                        </Item>
                }
                {
                    pmChatDataSelectDeselectSelector && pmChatDataSelectDeselectSelector === HEADER_MENU_SELECTION_TYPE.SELECT_ALL ?
                        <Item onClick={(event) => handlePmItemClick(event, HEADER_MENU_SELECTION_TYPE.DESELECT_ALL)}>
                            <i className="fa fa-check" aria-hidden="true"></i> SelectAll
                        </Item> :
                        <Item onClick={(event) => handlePmItemClick(event, HEADER_MENU_SELECTION_TYPE.SELECT_ALL)}>SelectAll</Item>
                }

                <Item onClick={(event) => handlePmItemClick(event, HEADER_MENU_SELECTION_TYPE.COPY)}>Copy</Item>
                <Item onClick={(event) => handlePmItemClick(event, HEADER_MENU_SELECTION_TYPE.PASTE)}>Paste</Item>
                <Separator />
                <Item onClick={(event) => handlePmItemClick(event)}>Print Screen an Area</Item>
            </Menu>

        </React.Fragment>

    );
};

export default EditContextMenu;