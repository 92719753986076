import React, { useEffect, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import FileContextMenu from 'src/components/clickContextMenu/fileContextMenu';
import EditContextMenu from 'src/components/clickContextMenu/editContextMenu';
import ActionContextMenu from 'src/components/clickContextMenu/actionContextMenu';
import FavouriteContextMenu from 'src/components/clickContextMenu/favouriteContextMenu';
import SettingContextMenu from 'src/components/clickContextMenu/settingContextMenu';
import HelpContextMenu from 'src/components/clickContextMenu/helpContextMenu';
import AdminContextMenu from 'src/components/clickContextMenu/adminContextMenu';
import { useToaster } from 'src/_common/hooks/actions/common/appToasterHook'
import { useUserApi } from 'src/_common/hooks/actions/user/appUserApiHook'
import { URLS, STORAGE, getNameInitials, HEADER_TYPE, CUSTOM_MESSAGE } from 'src/_config'
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook';
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector'
import { useAppGroupCategoryAction } from 'src/_common/hooks/actions/groupCategory/appGroupCategoryActionHook';
import { MainWindowHeaderMenu } from './headerMenuItems/mainWindow';
import { RoomWindowHeaderMenu } from './headerMenuItems/roomWindow';
import { PmWindowHeaderMenu } from './headerMenuItems/pmWindow';
import { useUserPreferenceApi } from 'src/_common/hooks/actions/userPreference/appUserPreferenceApiHook';
import Notification from './notification';
import { useAppPmWindowAction } from 'src/_common/hooks/actions/pmWindow/appPmWindowActionHook';
import SweetAlert from 'react-bootstrap-sweetalert';

interface HeaderProps {
  type?: string;
}

function Header({ type }: HeaderProps) {
  const userApi = useUserApi()
  const history = useHistory()
  const toast = useToaster()
  const userAction = useAppUserAction()
  const preference = useUserPreferenceApi();
  const groupCategoryAction = useAppGroupCategoryAction()
  const pmWindowAction = useAppPmWindowAction()
  const userSelector = useAppUserDetailsSelector()
  const [alert, setAlert] = useState<any>(null);

  const hideAlert = () => {
    setAlert(null);
  }

  // const handleLogout = (evt: any) => {
  //   evt && evt.preventDefault()
  //   setAlert(
  //     <SweetAlert
  //       warning
  //       showCancel
  //       confirmBtnText="Yes"
  //       cancelBtnText="No"
  //       cancelBtnBsStyle="danger"
  //       confirmBtnBsStyle="success"
  //       allowEscape={false}
  //       closeOnClickOutside={false}
  //       title="Log Out"
  //       onConfirm={() => LogOut()}
  //       onCancel={hideAlert}
  //       focusCancelBtn={true}
  //     >
  //       Are you sure you want to logout OutrighTalk?
  //     </SweetAlert>
  //   );
  // }

  const LogOut = (evt:any) => {
    evt && evt.preventDefault()
    userApi.callLogout((message: string, resp: any) => {
      if (resp) {
        localStorage.removeItem(STORAGE)
        localStorage.setItem('isAdult', '0');
        groupCategoryAction.emptyRoomListCategoryWise()
        userAction.logout()
        history.push(URLS.LOGIN)
      }
    }, (message: string) => {
      toast.error(message)
    })
  }

  const handleMyActiveRooms = (e: any) => {
    e.preventDefault();
    groupCategoryAction.activeRoomsPopDownHandler(true)
  }

  const handleMyActivePmWindow = (e: any) => {
    e.preventDefault();
    pmWindowAction.activePmWindowPopDownHandler(true)
  }

  //Update user current location API call
  const updateUserCurentLocation = (location: any) => {
    const params = {
      curr_loc_lat: location.coords.latitude,
      curr_loc_lon: location.coords.longitude
    }
    userApi.callUpdateCurrentLocation(params,
      (message: string) => {

      },
      (message: string) => {

      }
    )
  }

  //Below useEffect is used for Update user current location timer
  useEffect(() => {
    const update = async () => {
      navigator.geolocation.getCurrentPosition(updateUserCurentLocation, () => { })
    };
    const timer = setInterval(() => {
      update();
    }, 300000);

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    let params = {}
    preference.callGetUserPreference(params, (message: string, resp: any) => {

    }, (message: string) => {
      toast.error(message)
    })
  }, [])

  return (
    <React.Fragment>
      {alert}
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to={URLS.USER.DASHBOARD} className="logo logo-dark">
                <span className="logo-sm">
                  <img src="/img/logo.png" alt="" height={40} />
                </span>
                <span className="logo-lg">
                  <img src="/img/logo.png" alt="" height={40} />
                </span>
              </Link>
              <a href="#" className="logo logo-light">
                <span className="logo-sm">
                  <img src="/img/logo.png" alt="" height={40} />
                </span>
                <span className="logo-lg">
                  <img src="/img/logo.png" alt="" height={40} />
                </span>
              </a>
            </div>
          </div>
          <a href="#" onClick={(e) => handleMyActiveRooms(e)} className="my-active-btn"><span></span>Rooms I'm in</a>
          <a href="#" onClick={(e) => handleMyActivePmWindow(e)} className="my-active-btn"><span></span>My PMs</a>
          <div className="d-flex ml-auto">
            <div className="top-menu">
              {
                type == HEADER_TYPE.ROOM_WINDOW ?
                  <RoomWindowHeaderMenu />
                  :
                  type === HEADER_TYPE.PM_WINDOW ?
                    <PmWindowHeaderMenu /> :
                    <MainWindowHeaderMenu />
              }
            </div>

            <Notification />

            <div className="dropdown d-inline-block top-user-panel">
              <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div className="top-user-avatar">
                  {
                    userSelector && userSelector.avatar && userSelector.avatar.thumb ?
                      <img className="rounded-circle header-profile-user" src={userSelector.avatar.thumb} alt="Header Avatar" /> :
                      (<span className="header-avatar">{getNameInitials(userSelector?.username)}</span>)
                  }

                  <span className={userSelector && userSelector.visible_status ? 'top-user-status st-' + userSelector.visible_status : 'top-user-status st-1'} />

                </div>
                <span className="d-none d-xl-inline-block ml-1" key="t-henry">{userSelector && userSelector.username ? userSelector.username : '..'}</span>
                <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                <NavLink to={URLS.USER.MY_PROFILE} className="dropdown-item">
                  <i className="bx bx-user font-size-16 align-middle mr-1" />
                  <span key="t-profile">Profile</span>
                </NavLink>
                <a className="dropdown-item" href="#">
                  <i className="bx bx-wallet font-size-16 align-middle mr-1" />
                  <span key="t-my-wallet">My Wallet</span>
                </a>
                {/* <a className="dropdown-item d-block" href="#"> */}
                <NavLink to={URLS.USER.USER_PREFERENCES} className="dropdown-item d-block">
                  {/* <span className="badge badge-success float-right">11</span> */}
                  <i className="bx bx-wrench font-size-16 align-middle mr-1" />
                  <span key="t-settings">Preferences</span>
                </NavLink>
                {/* </a> */}
                {/* <a className="dropdown-item" href="#">
                  <i className="bx bx-lock-open font-size-16 align-middle mr-1" />
                  <span key="t-lock-screen">Lock screen</span>
                </a> */}
                {/* <div className="dropdown-divider" /> */}
                <a className="dropdown-item text-danger" href="#" onClick={(e) => LogOut(e)}>
                  <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger" /> <span key="t-logout">Logout</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <FileContextMenu />

      <EditContextMenu />

      <ActionContextMenu />

      <FavouriteContextMenu />

      <HelpContextMenu />

      <AdminContextMenu />

      <SettingContextMenu />

    </React.Fragment>
  )
}

export default Header
