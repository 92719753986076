import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useGroupCategoryApi } from 'src/_common/hooks/actions/groupCategory/appGroupCategoryApiHook';
import Slider from "react-slick";
import { useCommonApi } from 'src/_common/hooks/actions/commonApiCall/appCommonApiCallHook';
import { CRYPTO_SECRET_KEY } from 'src/_config';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import BuyVirtualGiftModal from 'src/components/commonModals/buyVitrualGift/buyVirtualGiftModal'
const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);
interface StickerBuyModalProps {
    onClose: () => void;
    shouldShow: boolean;
    byModalType: string;
    selectedContactList: any;
    entityId?: number;
    type: string;
}

const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipeToSlide: true,
    autoplay: false,
};

export default function StickerBuyModal({ onClose, shouldShow, byModalType, selectedContactList, entityId, type }: StickerBuyModalProps) {
    console.log("byModalType: ", byModalType);

    const commonApi = useCommonApi()

    // const [selectedCat, setSelectedCat] = useState<any>(null)
    const [stickerCategories, setStickerCategories] = useState<any>([]);
    const [stickerSubCategories, setStickerSubCategories] = useState<any>([]);
    const [categorywiseSticker, setCategorywiseSticker] = useState<any>([]);
    const [selectedStickerCategory, setSelectedStickerCategory] = useState<number>()
    const [selectedStickerSubCategory, setSelectedStickerSubCategory] = useState<number>()
    const [showBuyVirtualGift, setShowBuyVirtualGift] = useState<any>(false)
    const [availableCredit, setAvailableCredit] = useState<any>()

    // const { pmId } = useParams<any>();
    // const pm_id: number = parseInt(cryptr.decrypt(pmId));

    // const { roomId } = useParams<any>();
    // const r_id: number = parseInt(cryptr.decrypt(roomId));



    useEffect(() => {
        getStickerCategory()
    }, [])

    const getStickerCategory = () => {
        let params = {
            type: ['credit']
        }
        commonApi.callGetStickerCategories(params, (message: string, resp: any) => {
            if (resp && resp.categories && resp.categories.length) {
                setStickerCategories(resp.categories)
                if (resp.categories[0].children && resp.categories[0].children.length) {
                    setStickerSubCategories(resp.categories[0].children)
                    getCategorywiseSticker(null, resp.categories[0].id, resp.categories[0].children[0].id)
                } else {
                    setStickerSubCategories([])
                    getNonChildrenCategorywiseSticker(null, resp.categories[0].id)
                }
            }

            if (resp && resp.credit && resp.credit.credit) {
                setAvailableCredit(resp.credit.credit)
            } else {
                setAvailableCredit(0)
            }
        }, (message: string) => {

            toast.error(message)
        })
    }

    const getCategorywiseSticker = (e: any, pcatId: number, catId: number) => {
        if (e) {
            e.preventDefault()
        }
        setSelectedStickerCategory(pcatId)
        setSelectedStickerSubCategory(catId)
        let params = {
            category_id: pcatId,
            sub_category_id: catId,
        }
        commonApi.callGetAllStickerCategorywise(params, (message: string, resp: any) => {
            if (resp && resp.length) {
                console.log('resp sticker', resp)
                setCategorywiseSticker(resp)
            }
            else {
                setCategorywiseSticker([])
            }
        }, (message: string) => {

            toast.error(message)
        })
    }


    const getNonChildrenCategorywiseSticker = (e: any, catId: number) => {
        if (e) {
            e.preventDefault()
        }
        setSelectedStickerCategory(catId)
        let params = {
            category_id: catId,
        }
        commonApi.callGetAllStickerCategorywise(params, (message: string, resp: any) => {
            if (resp && resp.length) {
                console.log('resp sticker', resp)
                setCategorywiseSticker(resp)
                setStickerSubCategories([])
            }
            else {
                setStickerSubCategories([])
                setCategorywiseSticker([])
            }
        }, (message: string) => {

            toast.error(message)
        })
    }

    const fromSliderCategoryWise = (catId: number) => {
        let found = stickerCategories && stickerCategories.length ? stickerCategories.filter((x: any) => x.id == catId) : []
        if (found && found.length) {
            if (found[0].children && found[0].children.length) {
                setStickerSubCategories(found[0].children)
                console.log('found[0]', found[0])
                getCategorywiseSticker(null, found[0].children[0].pid, found[0].children[0].id)
            }
        }
    }



    // const selectSticker = (e: any,cat:any) => {
    //     e.preventDefault();
    //     // setSelectedCat(cat.id)
    // }

    const renderSlides = () =>
        stickerCategories.map((cat: any, index: any) => (
            <div className="slipper_category ">
                {
                    cat.children && cat.children.length ?

                        <a href="#"
                            className={cat.id == selectedStickerCategory ? 'active' : ''}
                            key={index} onClick={(e) => fromSliderCategoryWise(cat.id)}  >{cat.title}</a> :
                        <a href="#"
                            className={cat.id == selectedStickerCategory ? 'active' : ''}
                            key={index} onClick={(e) => getNonChildrenCategorywiseSticker(e, cat.id)}  >{cat.title}</a>
                }

            </div>

        ));


    const handleBuySticker = (e: any, stickerId: number) => {
        e.preventDefault()
        console.log('stickerId', stickerId)
        if (byModalType == 'ownStickerBuy') {
            if (stickerId) {
                let params = {
                    sticker_id: stickerId
                }
                commonApi.callStickerBuy(params, (message: string, resp: any) => {
                    onClose()

                }, (message: string) => {

                    toast.error(message)
                })
            } else {
                toast.error('Sticker id not found')
            }
        } else {

            if (stickerId) {
                let params = {
                    sticker_id: stickerId,
                    user_id: selectedContactList,
                    entity_id: entityId,
                    type
                }
                commonApi.callStickerGiftpurchase(params, (message: string, resp: any) => {
                    onClose()

                }, (message: string) => {

                    toast.error(message)
                })
            } else {
                toast.error('Sticker id not found')
            }

        }

    }

    const openBuyCreditModal = (e: any) => {
        e.preventDefault();
        setShowBuyVirtualGift(true)
    }

    const handleCloseBuyVirtualGiftModal = () => {
        setShowBuyVirtualGift(false)
    }

    return (
        <React.Fragment>
            <Modal
                show={shouldShow}
                backdrop="static"
                keyboard={false}
                size="xl"
                centered

            // dialogClassName="modal-dialog-scrollable"
            // contentClassName='custom-modal'
            >
                <Modal.Header>
                    <h5 className="modal-title mt-0">Add New Stickers</h5>
                    <div className="credits-wrap top_creadit">
                        <h2 className="available-credit">Available Credits : <span className="available-credit-value">{availableCredit}</span></h2>
                        <a href="#" onClick={(e) => openBuyCreditModal(e)} className="buy-credits">buy Credits</a>
                    </div>
                    <button type="button" className="close" onClick={() => onClose()}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </Modal.Header>
                <Modal.Body bsPrefix={'sticker-buy'} className="modal-body all_creadit_body_section">
                    {/* <div className="manage-video-message-panel text-center"> */}

                    <div className="new-sticker-tabs new_left_section">
                        {/* <a href="#" className="active">All gifts</a>
                        <a href="#">Stickers</a>
                        <a href="#">Rewards</a> */}

                        <Slider {...settings}>
                            {
                                renderSlides()
                            }
                        </Slider>

                    </div>
                    <div className="new-sticker-body new_right_section">
                        <div className="row">
                            {stickerSubCategories && stickerSubCategories.length ?
                                <div className="col-md-3">
                                    <ul className="sub-category left_panel_category" >
                                        {stickerSubCategories && stickerSubCategories.length ? stickerSubCategories.map((subcat: any) =>
                                            <li className={selectedStickerSubCategory == subcat.id ? 'active' : ''} onClick={() => getCategorywiseSticker(null, subcat.pid, subcat.id)}>
                                                {subcat.title}
                                            </li>
                                        ) : null}
                                    </ul>
                                </div> : null}
                            <div className={stickerSubCategories && stickerSubCategories.length ? "col-md-9 right_panel_sticker" : "col-md-12 right_panel_sticker"}>
                                {categorywiseSticker && categorywiseSticker.length ? categorywiseSticker.map((sticker: any) =>
                                    <div className="new-sticker-box">
                                        <div className="new-sticker-img">
                                            <img src={sticker.icon.thumb} alt="" />
                                        </div>
                                        {sticker.title ? <h2>{sticker.title}</h2> : null}
                                        {byModalType == 'ownStickerBuy' ?
                                            sticker.is_buy ? <a href="#" onClick={(e) => { e.preventDefault() }}>Already buy</a> :
                                                <a href="#" onClick={(e) => handleBuySticker(e, sticker.id)}>{sticker.credit_points} credits</a> :
                                            <a href="#" onClick={(e) => handleBuySticker(e, sticker.id)}>{sticker.credit_points} credits</a>}
                                    </div>
                                ) : <div className="new-sticker-box">

                                    <h2>No Sticker Found</h2>

                                </div>}



                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </Modal.Body>
            </Modal>
            {showBuyVirtualGift ?
                <BuyVirtualGiftModal
                    onClose={handleCloseBuyVirtualGiftModal}
                    // onCancel={}
                    shouldShow={showBuyVirtualGift}
                /> : null}

        </React.Fragment>
    )
}