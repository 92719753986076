// @ts-nocheck
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import './App.css';
import { URLS } from './_config';
import requireNoAuth from './_common/hoc/reqNoAuth';
import requireAuth from './_common/hoc/reqAuth';
import joinRoomGauard from './_common/hoc/joinRoomGauard';
import reqLayout from './_common/hoc/layout';
import Dashboard from './containers/dashboard';
import ManageVoiceMail from './containers/manageVoiceMail';
import ManageVideoMessage from './containers/manageVideoMessage';
import Login from './containers/login';
import Registration from './containers/registration';
import ForgotPassword from './containers/passwordReset/forgotpassword';
import ForgotPasswordOtp from './containers/passwordReset/forgotPasswordOtp';
import ResetPassword from './containers/passwordReset/resetpassword';
import ThankYou from './containers/thankYou';
import Page404 from './containers/page-404';
import MyProfile from './containers/myProfile';
import GroupsCategory from './containers/groupsCategory/list';
import RoomsList from './containers/groupsCategory/roomsList';
import RoomsDetails from './containers/groupsCategory/roomsDetail';
import MyNotebook from './containers/notebook/myNotebook';
import FindAndJoinRoom from './containers/groupsCategory/findAndJoinRoom/findAndJoinRoom';
import UserPreferences from './containers/userPreferences';

import FindNearByUser from './containers/findNearbyUser';
import ManageFavouriteRooms from './containers/favourites/manageFavouriteRooms/manageFavouriteRooms';
import MyRoom from './containers/favourites/myRoom/myRoom';

import PmRoomContainer from './containers/pm-room/pmRoom';
import Cms from './containers/cms/cms';
import CacheBuster from './CacheBuster';
import RoomSubscriptionSuccess from './containers/subscription/room/success/success';
import RoomSubscriptionFailure from './containers/subscription/room/failure/failure';
import UpgradeNicknameSuccess from './containers/subscription/nickname/success/upgradeSuccess';
import UpgradeNicknameFailure from './containers/subscription/nickname/failure/upgradeFailure';

import BuyCreditSuccess from './containers/buyCredit/success/buyCreditSuccess';
import BuyCreditFailure from './containers/buyCredit/failure/buyCreditFailure';

function App() {

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <React.Fragment>
            <Router>
              <Switch>
                <Route exact path={URLS.HOME} component={() => (
                  <Redirect to={URLS.LOGIN} />
                )} />
                <Route exact path={URLS.USER.DASHBOARD} component={requireAuth(Dashboard)} />
                <Route exact path={URLS.USER.MANAGE_VOICE_MAIL} component={requireAuth(ManageVoiceMail)} />
                <Route exact path={URLS.USER.MANAGE_VIDEO_MESSAGE} component={requireAuth(ManageVideoMessage)} />
                <Route exact path={URLS.USER.NOTEBOOK} component={requireAuth(MyNotebook)} />
                <Route exact path={URLS.USER.FIND_AND_JOIN_ROOM} component={requireAuth(FindAndJoinRoom)} />
                <Route exact path={URLS.USER.MY_PROFILE} component={requireAuth(MyProfile)} />
                <Route exact path={URLS.USER.GROUPS_AND_CATEGORY} component={requireAuth(GroupsCategory)} />
                <Route exact path={URLS.USER.ROOMS} component={requireAuth(RoomsList)} />
                <Route exact path={URLS.USER.ROOMS_DETAILS} component={joinRoomGauard(requireAuth(RoomsDetails))} />

                <Route exact path={URLS.USER.NEAR_BY_USER} component={requireAuth(FindNearByUser)} />
                <Route exact path={URLS.USER.MY_ROOMS} component={requireAuth(MyRoom)} />
                <Route exact path={URLS.USER.MANAGE_FAVOURITE_ROOMS} component={requireAuth(ManageFavouriteRooms)} />

                <Route exact path={URLS.USER.USER_PREFERENCES} component={requireAuth(UserPreferences)} />

                <Route exact path={URLS.PM_WINDOW.PM_ROOM} component={requireAuth(PmRoomContainer)} />

                <Route exact path={URLS.CMS} component={requireAuth(Cms)} />

                {/* Subscription */}
                <Route exact path={URLS.SUBSCRIPTION.ROOM_SUCCESS_CALLBACK} component={RoomSubscriptionSuccess} />
                <Route exact path={URLS.SUBSCRIPTION.ROOM_FAILURE_CALLBACK} component={RoomSubscriptionFailure} />

                {/* Nickname Upgradation */}
                <Route exact path={URLS.SUBSCRIPTION.NICKNAME_SUCCESS_CALLBACK} component={requireAuth(UpgradeNicknameSuccess)} />
                <Route exact path={URLS.SUBSCRIPTION.NICKNAME_FAILURE_CALLBACK} component={requireAuth(UpgradeNicknameFailure)} />

                {/* Buy Credit  */}
                <Route exact path={URLS.BUY_CREDIT.CREDIT_SUCCESS_CALLBACK} component={BuyCreditSuccess} />
                <Route exact path={URLS.BUY_CREDIT.CREDIT_FAILURE_CALLBACK} component={BuyCreditFailure} />

                <Route exact path={URLS.LOGIN} component={requireNoAuth(Login)} />
                <Route exact path={URLS.REGISTRATION} component={requireNoAuth(Registration)} />
                <Route exact path={URLS.THANK_YOU} component={requireNoAuth(ThankYou)} />
                <Route exact path={URLS.FORGOT_PASSWORD_OTP} component={requireNoAuth(ForgotPasswordOtp)} />
                <Route exact path={URLS.RESET_PASSWORD} component={requireNoAuth(ResetPassword)} />
                <Route exact path={URLS.FORGOT_PASSWORD} component={requireNoAuth(ForgotPassword)} />
                <Route path="*" component={reqLayout(Page404)} />

              </Switch>
            </Router>
          </React.Fragment>
        );
      }}
    </CacheBuster>
  );
}

export default App;
