import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { usePmWindowApi } from 'src/_common/hooks/actions/pmWindow/appPmWindowApiHook';
import { useAppPmWindowDetails } from 'src/_common/hooks/selectors/pmWindowSelector';
import { useAppUserDetailsSelector } from 'src/_common/hooks/selectors/userSelector';
import { CRYPTO_SECRET_KEY } from 'src/_config';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

interface OtherPmUsersProps {
    onWindowClose: () => void;
    streams: any[];
}

const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    swipeToSlide: true,
    autoplay: false,
};

const others = [
    {
        id: 116,
        name: 'Mary'
    },
    {
        id: 2,
        name: 'Mary2'
    },
    {
        id: 3,
        name: 'Mary3'
    }
]

export default function OtherPmUsers({ onWindowClose, streams }: OtherPmUsersProps) {

    const pmWindowDetailsSelector = useAppPmWindowDetails()
    const userSelector = useAppUserDetailsSelector()
    const pmWindowApi = usePmWindowApi()
    const history = useHistory()
    const { pmId } = useParams<any>();
    const pm_id: number = parseInt(cryptr.decrypt(pmId));

    const handleCloseOthersRoomPmUsersWindow = (e: React.MouseEvent) => {
        e.preventDefault()
        // onWindowClose()
        let params = {
            pm_id: pm_id
        }
        pmWindowApi.callExitPmWindow(params, (message: string, resp: any) => {

            history.push('dashboard')

        }, (message: string) => {

        })

    }


    const renderSlides = () =>
        // streams && streams.length ? streams.map((other: any, index: any) => (
        //     <div className="ml-2" key={index} id={'subscriber-' + other.userId}>
        //         <div className="room-box">
        //             <a href="#" className="exit-room">
        //                 <img src="/img/close-icon.png" alt="close" /></a>
        //             <img src="/img/user-01.jpg" alt="" />
        //             <div className="room-box-name">{other.userId}</div>
        //         </div>
        //     </div>
        // )) :
        pmWindowDetailsSelector && pmWindowDetailsSelector.users && pmWindowDetailsSelector.users.length && pmWindowDetailsSelector.users.map((other: any, index: any) => (
            <div className="ml-2" key={index}>
                {
                    other.user_id === userSelector?.id ?
                        <div className="room-box">
                            <div id={'camera-me'}>
                                {
                                    other.user_info && other.user_info.avatar ?
                                        <img src={other.user_info.avatar.thumb} alt={other.user_info.username} id={"img-" + other.user_id} /> :
                                        <img src="/img/user-01.jpg" alt="" id={"img-" + other.user_id} />
                                }
                            </div>
                            <div className="room-box-name">{other.user_info.username}</div>
                        </div> :
                        <div className="room-box">
                            <div id={'subscriber-' + other.user_id}>
                                {
                                    other.user_info && other.user_info.avatar ?
                                        <img src={other.user_info.avatar.thumb} alt={other.user_info.username} id={"img-" + other.user_id} /> :
                                        <img src="/img/user-01.jpg" alt="" id={"img-" + other.user_id} />
                                }
                            </div>
                            <div className="room-box-name">{other.user_info.customize_nickname && other.user_info.customize_nickname.nickname ? other.user_info.customize_nickname.nickname : other.user_info.username}</div>
                        </div>
                }
            </div>
        ))
        ;

    return (
        <div className="in-room-wrapper">
            {/* <a href="#"
                className="close-box"
                onClick={(e) => handleCloseOthersRoomPmUsersWindow(e)}
            >
                {/* <img src="/img/close-icon.png" alt="close" /> 
                {/* Exit 
                </a> */}
            <div className="in-room-content">
                <div className="room-list">
                    <Slider {...settings}>
                        {
                            renderSlides()
                        }
                    </Slider>
                </div>
            </div>
        </div>
    )
}