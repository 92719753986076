import React, { useState } from 'react';
import { Menu, Item, Separator, Submenu } from 'react-contexify';
import { useAppGroupCategoryAction } from 'src/_common/hooks/actions/groupCategory/appGroupCategoryActionHook';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useUserApi } from '../../_common/hooks/actions/user/appUserApiHook';
import { useToaster } from '../../_common/hooks/actions/common/appToasterHook';
import { UpdateVisibilityStatus } from '../../_common/interfaces/ApiReqRes';
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook';
import { URLS, STORAGE, CRYPTO_SECRET_KEY } from 'src/_config'
import { useGroupCategoryApi } from 'src/_common/hooks/actions/groupCategory/appGroupCategoryApiHook';
import { useAppRoomDetailsSelector } from 'src/_common/hooks/selectors/groupCategorySelector';
import RoomDetailsModal from '../commonModals/roomDetailsModal/roomDetailsModal';
import { useAppPmWindowDetails } from 'src/_common/hooks/selectors/pmWindowSelector';
import ContactListForGiftModal from 'src/components/commonModals/contactListForGiftSend/ContactListModal';
import StickerBuyModal from 'src/components/commonModals/stickerBuyModal/stickerBuyModal';
import CustomizedPersonalMessageModal from '../commonModals/customizedPersonalMessageModal/customizedPersonalMessageModal';
import SweetAlert from 'react-bootstrap-sweetalert';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

const FileContextMenu = (props: any) => {

    const userApi = useUserApi()
    const toast = useToaster()
    const userAction = useAppUserAction()
    const history = useHistory()
    const groupCategoryApi = useGroupCategoryApi()
    const groupCategoryAction = useAppGroupCategoryAction()
    const roomDetailsSelector = useAppRoomDetailsSelector()
    const pmWindowDetailsSelector = useAppPmWindowDetails()
    const [showRoomDetailsModal, setShowRoomDetailsModal] = useState<boolean>(false)
    const [alert, setAlert] = useState<any>(null);
    const [showStickerBuyModal, setShowStickerBuyModal] = useState<boolean>(false)
    const [showContactListModal, setContactListModalModal] = useState<boolean>(false)
    const [byStickerModalType, setByStickerModalType] = useState<any>()
    const [selectedContactList, setSelectedContactList] = useState<any>([])

    const [showCustomizedPersonalMessageModal, setShowCustomizedPersonalMessageModal] = useState<boolean>(false)


    const handleItemClick = (e: any) => {
    }

    const handleOpenRoomDetailsModal = () => {
        setShowRoomDetailsModal(true)
    }

    const handleCloseRoomDetailsModal = () => {
        if (showRoomDetailsModal) setShowRoomDetailsModal(false)
    }

    const handleStatusChange = (e: any, status: number) => {
        const params: UpdateVisibilityStatus = {
            visible_status: status
        }
        userApi.callUpdateUserVisibilityStatus(params, (message: string, resp: any) => {
            if (resp) {
            } else {
                toast.error(message)
            }
        }, (message: string, resp: any) => {
            toast.error(message)
        })
    }
    const LogOut = () => {
        userApi.callLogout((message: string, resp: any) => {
            if (resp) {
                localStorage.removeItem(STORAGE)
                localStorage.setItem('isAdult', '0');
                groupCategoryAction.emptyRoomListCategoryWise()
                userAction.logout()
                history.push(URLS.LOGIN)
            }
        }, (message: string) => {
            toast.error(message)
        })
    }

    const gotoProfilePage = () => {
        history.push(URLS.USER.MY_PROFILE)
    }

    const gotoMyNotebookPage = (e: any) => {
        history.push(URLS.USER.NOTEBOOK)
    }

    const gotoMyPreference = () => {
        history.push(URLS.USER.USER_PREFERENCES)
    }

    const hideAlert = () => {
        setAlert(null);
    }

    const showAlert = () => {
        
        setAlert(
            <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes"
                cancelBtnText="No"
                cancelBtnBsStyle="success"
                confirmBtnBsStyle="danger"
                allowEscape={false}
                closeOnClickOutside={false}
                title={`Exit OutrighTalk`}
                onConfirm={() => LogOut()}
                onCancel={hideAlert}
                focusCancelBtn={true}
            >
                {`Are you sure you want to exit OutrighTalk?`}
            </SweetAlert>
        );
    }

    const handleExitRoom = () => {
        if (roomDetailsSelector && roomDetailsSelector.room && roomDetailsSelector.room.id) {
            var params = {
                room_id: roomDetailsSelector.room.id,
            }
            groupCategoryApi.callExitFromRoom(params, (message: string, resp: any) => {
                toast.success(message)
                history.push(URLS.USER.GROUPS_AND_CATEGORY)

            }, (message: string) => {
                toast.error(message)
            })
        }
        else {
            toast.error('Room Id not found')
        }
    }

    //For send virtual gift

    const openContactListModal = (e: any) => {
        // e.preventDefault()
        setContactListModalModal(true)
    }

    const contactListCloseModal = () => {
        if (showContactListModal) setContactListModalModal(false)
    }

    const openGiftSticker = (contactList: any) => {
        setSelectedContactList(contactList)
        // setByStickerModalType('giftSendStickerBuy')
        setShowStickerBuyModal(true)
    }

    //for stricker buy modal close
    const handleOnCloseSticker = () => {
        setShowStickerBuyModal(false)
        setSelectedContactList([])
        // setByStickerModalType('')
        // getStickerCategory()

    }

    //For customized message modal
    const handleOpenCustomizedMessageModal = () => {
        setShowCustomizedPersonalMessageModal(true)
    }

    const handleCloseCustomizedMessageModal = () => {
        if (showCustomizedPersonalMessageModal) setShowCustomizedPersonalMessageModal(false)
    }

    const handleClearAboutMessage = () => {
        userApi.callCLearAboutMessage((message: string, resp: any) => {
            if (resp) {
                userAction.manageAboutMessage(null)
                toast.success(message)
            }
        }, (message: string) => {
            toast.error(message)
        })
    }

    return (
        <React.Fragment>
             {alert}
            <Menu id='menu_header_file_id' className="header-click-menu">
                <Submenu label="Change my status">
                    <Item onClick={(e) => handleStatusChange(e, 1)}>Available</Item>
                    <Item onClick={(e) => handleStatusChange(e, 2)}>Away</Item>
                    <Item onClick={(e) => handleStatusChange(e, 3)}>DND (Do not Disturb)</Item>
                    <Item onClick={(e) => handleStatusChange(e, 4)}>Invisible</Item>
                </Submenu>
                <Submenu label="Show the room I’m in to">
                    <Item onClick={(e) => handleItemClick(e)}>My contacts only</Item>
                    <Item onClick={(e) => handleItemClick(e)}>All users</Item>
                    <Item onClick={(e) => handleItemClick(e)}>No one.</Item>
                </Submenu>
                <Item onClick={handleOpenCustomizedMessageModal}>Customize a personal message</Item>
                <Item onClick={handleClearAboutMessage}>Clear About message</Item>
                <Separator />
                <Item onClick={() => gotoMyPreference()}>Preferences</Item>
                <Item onClick={(event) => gotoMyNotebookPage(event)}>My Notebook</Item>
                <Item onClick={() => gotoProfilePage()}>My OutrigTalk</Item>
                <Separator />
                {/* <Item onClick={(event) => handleItemClick(event.props.profile)}>Delete</Item> */}
                <Item onClick={(event) => handleItemClick(event)}>Outrightalk e-Store</Item>
                <Item onClick={(event) => handleItemClick(event)}>Skins</Item>
                <Item onClick={(event) => handleItemClick(event)}>Language</Item>
                <Item onClick={(event) => handleItemClick(event)}>Received Files</Item>
                <Separator />
                <Item onClick={(e) => handleItemClick(e)}>Chat History</Item>
                <Item onClick={() => showAlert()}>Exit</Item>
            </Menu>

            <Menu id='room_header_menu_file_id' className="header-click-menu">
                <Item onClick={(event) => handleItemClick(event)}>Save</Item>
                <Item onClick={(event) => handleItemClick(event)} className="disable-link" >Autosave</Item>
                <Separator />
                <Item onClick={handleOpenRoomDetailsModal}>Room Details</Item>
                <Separator />
                <Item onClick={() => handleExitRoom()}>Exit Room</Item>
            </Menu>

            <Menu id='pm_header_file_id' className="header-click-menu">
                <Item onClick={(event) => openContactListModal(event)}>Send a Virtual Gift</Item>
                <Item onClick={(event) => handleItemClick(event)}>Buy Gift Subscription</Item>
                <Separator />
                <Item onClick={(event) => handleItemClick(event)}>Save Conversation</Item>
                <Item onClick={(event) => handleItemClick(event)}>Close Conversation</Item>
            </Menu>

            {
                showRoomDetailsModal &&
                <RoomDetailsModal
                    shouldShow={showRoomDetailsModal}
                    onClose={handleCloseRoomDetailsModal}
                    roomId={roomDetailsSelector.room.id}
                />
            }

            {
                showStickerBuyModal ?
                    <StickerBuyModal
                        onClose={handleOnCloseSticker}
                        shouldShow={showStickerBuyModal}
                        byModalType={'giftSendStickerBuy'}  //ownStickerBuy or giftSendStickerBuy
                        selectedContactList={selectedContactList}
                        entityId={pmWindowDetailsSelector.id}
                        type={'pm'}
                    /> : null
            }

            {
                showContactListModal ?
                    <ContactListForGiftModal
                        onClose={contactListCloseModal}
                        onSuccess={openGiftSticker}
                        shouldShow={showContactListModal}
                        type={'stickerGiftSend'}
                    /> : null
            }

            {
                showCustomizedPersonalMessageModal ?
                    <CustomizedPersonalMessageModal
                        title="Customized Personal Message"
                        onClose={handleCloseCustomizedMessageModal}
                        shouldShow={showCustomizedPersonalMessageModal}
                    /> : null
            }

        </React.Fragment>

    );
};

export default FileContextMenu;