import React, { useState } from 'react';
import { Menu, Item, Separator, Submenu } from 'react-contexify';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useAppGroupCategoryAction } from 'src/_common/hooks/actions/groupCategory/appGroupCategoryActionHook';
import { useAppChangePasswordModalOpen, useAppFindAndAddUserModalOpen, useAppUserDetailsSelector, useAppMultiRecipientMessageModalOpen } from 'src/_common/hooks/selectors/userSelector'
import { useAppUserAction } from 'src/_common/hooks/actions/user/appUserActionHook';
import { CRYPTO_SECRET_KEY, FIND_AND_ADD_USER_TYPE, getBooleanStatus, getRoomSubscriptionTitle, getSubscriptionColor, MENU_OPERATIONS, URLS } from 'src/_config';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import RoleBasedContextMenuHOC from 'src/_common/hoc/roleBasedRoomSideContextMenuGauard';
import ChangePasswordModal from 'src/components/myProfile/changePasswordModal';
import FindAndAddUserModal from '../commonModals/findAndAddUserModal/findAndAddUserModal';
import UploadRoomVideoModal from '../../components/groupsCategory/roomsDetail/modal/uploadRoomVideoModal';
import CreateRoomTwoModal from 'src/components/groupsCategory/roomsList/createRoomModal/createRoomModalTwo';
import PmUsersListModal from '../pm-room/modal/pmUsersListModal/pmUsersListModal';
import WhoIsViewingMeModal from '../groupsCategory/roomsDetail/modal/whoIsViewingMeModal';
import { useAppRoomDetailsSelector } from 'src/_common/hooks/selectors/groupCategorySelector';
import { useGroupCategoryApi } from 'src/_common/hooks/actions/groupCategory/appGroupCategoryApiHook';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { useAppPmWindowDetails } from 'src/_common/hooks/selectors/pmWindowSelector';
import { usePmWindowApi } from 'src/_common/hooks/actions/pmWindow/appPmWindowApiHook';
import MultiRecipientMessageModal from '../commonModals/multiRecipientMessageModal/multiRecipientMessageModal';
import JoinChatRoomAsAdmin from '../commonModals/joinChatRoomAsAdmin/joinChatRoomAsAdmin';
import ChangeAccount from '../commonModals/changeAccount/changeAccount';
import DigSoundListModal from '../pm-room/modal/digSoundListModal/digSoundListModal';
import UpgradeRoomSubscriptionModal from '../groupsCategory/roomsDetail/modal/upgradeRoomSubscriptionModal';
import UpgradeNicknameSubscriptionModal from '../commonModals/upgradeNicknameModal/upgradeNicknameModal';
import { useUserApi } from 'src/_common/hooks/actions/user/appUserApiHook';
const Cryptr = require('cryptr');
const cryptr = new Cryptr(CRYPTO_SECRET_KEY);

const ActionContextMenu = (props: any) => {

    const { groupId, roomId } = useParams<any>();
    const changePasswordModalOpenSelector = useAppChangePasswordModalOpen()
    const findAndAddUserModalOpenSelector = useAppFindAndAddUserModalOpen()
    const groupCategoryAction = useAppGroupCategoryAction()
    const groupCategoryApi = useGroupCategoryApi()
    const pmWindowDetailsSelector = useAppPmWindowDetails()
    const userAction = useAppUserAction()
    const userSelector = useAppUserDetailsSelector()
    const roomDetailsSelector = useAppRoomDetailsSelector()
    const history = useHistory()
    const [showCreateRoomModal, setCreateRoomModal] = useState<boolean>(false)
    const [showRoomVideoUploadModal, setShowRoomVideoUploadModal] = useState<boolean>(false);
    const [showWhoIsViewingMyWebcamModal, setShowWhoIsViewingMyWebcamModal] = useState<boolean>(false);
    const [joinChatRoomAsAdmin, setJoinChatRoomAsAdmin] = useState<boolean>(false);
    const isOpenMultiRecipientMessageModal = useAppMultiRecipientMessageModalOpen()

    const [changeMyAccount, setChangeMyAccount] = useState<boolean>(false);

    const pmWindowApi = usePmWindowApi()
    const { pmId } = useParams<any>();

    const [showPmUsersListModal, setPmUsersListModal] = useState<boolean>(false)
    const [showDigSoundListModal, setDigSoundListModal] = useState<boolean>(false)
    const [showUpgradeRoomSubscriptionModal, setUpgradeRoomSubscriptionModal] = useState<boolean>(false)
    const [showUpgradeNicknameSubscriptionModal, setUpgradeNicknameSubscriptionModal] = useState<boolean>(false)
    const [alert, setAlert] = useState<any>(null);
    const userApi = useUserApi()

    const createRoomModalOpen = (e: any) => {
        setCreateRoomModal(true);
    }

    const createRoomCloseModal = () => {
        if (showCreateRoomModal) setCreateRoomModal(false)
    }

    const whoIsViewingMeModalOpen = (e: any) => {
        setShowWhoIsViewingMyWebcamModal(true)
    }

    const whoIsViewingMeModalClose = () => {
        if (showWhoIsViewingMyWebcamModal) setShowWhoIsViewingMyWebcamModal(false)
    }

    const joinChatRoomAsAdminModalOpen = () => {
        setJoinChatRoomAsAdmin(true)
    }
    const joinChatAsAdminModalClose = () => {
        setJoinChatRoomAsAdmin(false)
    }

    const handleItemClick = (e: any) => {
    }

    const handleFindAndJoinRoom = (e: any) => {
        history.push(URLS.USER.FIND_AND_JOIN_ROOM);
    }

    const handleFindAndAddUser = (e: any) => {
        // history.push(URLS.USER.FIND_AND_ADD_USER);
        userAction.showFindAndAddUserModal(true, FIND_AND_ADD_USER_TYPE.BOTH, null)
    }

    const handleChangePassowrd = (e: any) => {
        userAction.showChangePasswordModal(true);
    }

    const gotoPage = (page: string) => {
        switch (page) {
            case "voiceMail":
                history.push(URLS.USER.MANAGE_VOICE_MAIL)
                break;
            case "videoMessage":
                history.push(URLS.USER.MANAGE_VIDEO_MESSAGE)
                break;
            case "findAndJoinRoom":
                history.push(URLS.USER.FIND_AND_JOIN_ROOM);
                break;
            case "createRoom":
                history.push(URLS.USER.GROUPS_AND_CATEGORY);
                break;
            case "findNearbyUsers":
                history.push(URLS.USER.NEAR_BY_USER);
                break;
        }
    }

    const getRoomDetails = () => {
        const params = {
            room_id: parseInt(cryptr.decrypt(roomId))
        };
        groupCategoryApi.callGetRoomDetails(params, (message: string, resp: any) => {
            if (resp && resp.list && resp.list.length) {
            }
        }, (message: string) => {
            // toast.error(message)
        })
    }

    const handleLockMickAndPushToTalk = (operation: string) => {
        groupCategoryAction.resetNormalUserMicHandleIsChanged(true)
        const params = {
            room_id: parseInt(cryptr.decrypt(roomId)),
            key_name: operation, // 'lock_mic' : 'push_to_talk',
            key_value: roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings[operation] ? 0 : 1
        }
        groupCategoryApi.callChangeUserRoomSettings(params, (message: string, resp: any) => {
            if (resp) {
                getRoomDetails()
            }
        }, (message: string) => {
            toast.error(message)
        })
    }

    const handleClearMyChat = () => {
        const params = {
            room_id: parseInt(cryptr.decrypt(roomId)),
        }
        groupCategoryApi.callClearRoomChat(params, (message: string, resp: any) => {
            if (resp) {
                getAllChatFromRoom()
            }
        }, (message: string) => {
            toast.error(message)
        })
    }

    const getAllChatFromRoom = () => {
        const params = {
            room_id: parseInt(cryptr.decrypt(roomId)),
        };
        groupCategoryApi.callGetAllChatFromRoom(params, (message: string, resp: any) => {
            if (resp && resp.chatfile) {
            }
        }, (message: string) => {
            // toast.error(message)
        })
    }

    const handlePlayVideo = () => {
        setShowRoomVideoUploadModal(true)
    }

    const roomVideoUploadCloseModal = () => {
        if (showRoomVideoUploadModal) setShowRoomVideoUploadModal(false)
    }

    //Pm setting related Api call

    const getPmWindowDetails = () => {
        let params = {
            pm_id: parseInt(cryptr.decrypt(pmId))
        }
        pmWindowApi.callGetPmsDetails(params, (message: string, resp: any) => {

        }, (message: string) => {
            toast.error(message)
        })
    }

    const handleMicHandle = (operation: string) => {
        const params = {
            pm_id: parseInt(cryptr.decrypt(pmId)),
            key_name: operation,
            key_value: pmWindowDetailsSelector && pmWindowDetailsSelector.pm_settings && pmWindowDetailsSelector.pm_settings[operation] ? 0 : 1
        }
        pmWindowApi.callPmHeaderMenuSettingActionUpdate(params, (message: string, resp: any) => {
            if (resp) {
                getPmWindowDetails()
            }

        }, (message: string) => {

        })

    }

    const handleOpenMultiRecipientMessageModal = () => {
        userAction.showMultiRecipientMessageModal(true)
    }

    const handleChangeMyAccount = () => {
        setChangeMyAccount(true)
    }

    const changeMyAccountModalClose = () => {
        setChangeMyAccount(false)
    }

    //Pm Action

    const openPmUsersListModal = () => {
        setPmUsersListModal(true)
    }

    const closePmUsersListModal = () => {
        if (showPmUsersListModal) setPmUsersListModal(false)
    }

    //Dig sound
    const openDigSoundListModal = () => {
        setDigSoundListModal(true)
    }

    const closeDigSoundListModal = () => {
        if (showDigSoundListModal) setDigSoundListModal(false)
    }

    //upgrade room subscription
    const upgradeRoomSubscriptionOpenModal = () => {
        setUpgradeRoomSubscriptionModal(true);
    }

    const upgradeRoomSubscriptionCloseModal = () => {
        if (showUpgradeRoomSubscriptionModal) setUpgradeRoomSubscriptionModal(false)
    }

    //Upgrade Nickname Subscription
    const upgradeNicknameSubscriptionOpenModal = () => {
        setUpgradeNicknameSubscriptionModal(true);
    }

    const upgradeNicknameSubscriptionCloseModal = () => {
        if (showUpgradeNicknameSubscriptionModal) setUpgradeNicknameSubscriptionModal(false)
    }

    //Init Api call
    const userMeCall = () => {
        userApi.callGetMe((message: string, resp: any) => {
        }, (message: string, resp: any) => {
            toast.error(message)
        })
    }


    // const hideAlert = () => {
    //     setAlert(null);
    // }

    // const removeRoomSubscription = () => {
    //     const params = {
    //         room_id: parseInt(cryptr.decrypt(roomId))
    //     }
    //     groupCategoryApi.callRemoveRoomSubscription(params, (message: string, resp: any) => {
    //         if (resp) {
    //             hideAlert()
    //             getRoomDetails()
    //         }
    //     }, (message: string) => {
    //         toast.error(message)
    //         console.error("Error at room subscription remove");
    //     })
    // }

    // const handleRemoveRoomSubscription = () => {
    //     setAlert(
    //         <SweetAlert
    //             warning
    //             showCancel
    //             confirmBtnText="Yes"
    //             cancelBtnText="No"
    //             cancelBtnBsStyle="success"
    //             confirmBtnBsStyle="danger"
    //             allowEscape={false}
    //             closeOnClickOutside={false}
    //             title={`Remove Subscription`}
    //             onConfirm={() => removeRoomSubscription()}
    //             onCancel={hideAlert}
    //             focusCancelBtn={true}
    //         >
    //             {`Are you sure you want to remove room subscription?`}
    //         </SweetAlert>
    //     );
    // }

    return (
        <React.Fragment>
            {alert}
            <Menu id='menu_header_action_id' className="header-click-menu">
                <Item onClick={upgradeNicknameSubscriptionOpenModal}>Upgrade Nickname</Item>
                <Separator />
                <Item onClick={(event) => gotoPage('voiceMail')}>Manage Voicemails</Item>
                <Item onClick={(event) => gotoPage('videoMessage')}>Manage Video Messages</Item>
                <Item onClick={(event) => handleItemClick(event)}>PM a User</Item>
                <Item onClick={(event) => handleFindAndAddUser(event)}>Find &amp; Add a User</Item>
                <Item onClick={() => handleOpenMultiRecipientMessageModal()}>Multi-recipient Messages</Item>
                <Item onClick={(event) => gotoPage('findAndJoinRoom')}>Find &amp; Join a Room</Item>
                <Item onClick={(event) => gotoPage('findNearbyUsers')}>Find users nearby</Item>
                <Item onClick={(event) => joinChatRoomAsAdminModalOpen()}>Join a chat room as Admin</Item>
                {
                    // getBooleanStatus(userSelector && userSelector.allow_create_room) ?
                    <Item onClick={(event) => createRoomModalOpen(event)} disabled={getBooleanStatus(userSelector && userSelector.allow_create_room) ? false : true}>Create a Room</Item>
                    // : null

                }
                <Item onClick={(event) => handleChangePassowrd(event)}>Change my Password</Item>
                <Item onClick={handleChangeMyAccount}>Change my Account</Item>
            </Menu>

            <Menu id='room_header_menu_action_id' className="header-click-menu">
                {/* <Item onClick={(event) => handleItemClick(event)}>Invite</Item>
                <Separator /> */}
                <Item onClick={upgradeNicknameSubscriptionOpenModal}>Upgrade Nickname</Item>
                {/* <Separator /> */}
                {/* Below menu ony available to owner */}
                <RoleBasedContextMenuHOC role={[3]}>
                    {/* {
                        roomDetailsSelector && roomDetailsSelector.room && roomDetailsSelector.room.subscription_info ?
                            <Item onClick={handleRemoveRoomSubscription}>Remove Room Subscription
                                <span style={{
                                    color: getSubscriptionColor(roomDetailsSelector && roomDetailsSelector.room ? roomDetailsSelector.room : null)
                                }}>
                                    ({
                                        getRoomSubscriptionTitle(roomDetailsSelector && roomDetailsSelector.room ? roomDetailsSelector.room : null)

                                    })
                                </span>

                            </Item> :
                            <Item onClick={upgradeRoomSubscriptionOpenModal}>Upgrade Room Subscription</Item>
                    } */}
                    <Item onClick={upgradeRoomSubscriptionOpenModal}>Upgrade Room Subscription</Item>
                </RoleBasedContextMenuHOC>
                {/* <Item onClick={(event) => handleItemClick(event)}>Start my Webcam</Item> */}
                <Separator />
                <Item onClick={(event) => whoIsViewingMeModalOpen(event)}>Who is viewing me</Item>
                <Item onClick={() => handlePlayVideo()}>Play a Video</Item>
                <Item onClick={() => handleClearMyChat()}>Clear my Text Chat</Item>
                <Submenu label="Talk">
                    {
                        <Item onClick={(e) => handleLockMickAndPushToTalk(MENU_OPERATIONS.PUSH_TO_TALK)}
                            className={
                                clsx({
                                    'disable-link': roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings.push_to_talk
                                })
                            }
                        >
                            {
                                roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings.push_to_talk ?
                                    <i className="fa fa-check" aria-hidden="true"></i> : null
                            }
                            Push to Talk
                        </Item>
                    }


                    {

                        <Item
                            className={
                                clsx({
                                    'disable-link': roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings.lock_mic
                                })
                            }
                            onClick={(e) => handleLockMickAndPushToTalk(MENU_OPERATIONS.LOCK_MIC)}>
                            {
                                roomDetailsSelector && roomDetailsSelector.user && roomDetailsSelector.user.room_user_settings && roomDetailsSelector.user.room_user_settings.lock_mic ?
                                    <i className="fa fa-check" aria-hidden="true"></i> : null
                            }
                            Lock Mic (Hands Free)
                        </Item>
                    }
                </Submenu>
            </Menu>

            <Menu id='pm_header_actions_id' className="header-click-menu">
                <Item onClick={(event) => openPmUsersListModal()}>View Profile</Item>
                <Item onClick={(event) => handleItemClick(event)}>Add to List</Item>
                <Item onClick={(event) => handleItemClick(event)}>Remove from List</Item>
                <Item onClick={(event) => handleItemClick(event)}>Block the User</Item>
                <Item onClick={(event) => handleItemClick(event)}>Start my Webcam</Item>
                <Item onClick={(event) => handleItemClick(event)}>Customize Nickname</Item>
                <Item onClick={(event) => handleItemClick(event)}>Invite Others</Item>
                <Separator />
                <Submenu label="Talk">
                    <Item onClick={() => handleMicHandle('push_to_talk')}>
                        {pmWindowDetailsSelector && pmWindowDetailsSelector.pm_settings && pmWindowDetailsSelector.pm_settings.push_to_talk ?
                            <i className="fa fa-check" aria-hidden="true"></i> : null
                        }
                        Push to Talk

                    </Item>
                    <Item onClick={() => handleMicHandle('lock_mic')}>
                        {pmWindowDetailsSelector && pmWindowDetailsSelector.pm_settings && pmWindowDetailsSelector.pm_settings.lock_mic ?
                            <i className="fa fa-check" aria-hidden="true"></i> : null
                        }
                        Lock Mic (Hands Free)

                    </Item>
                </Submenu>
                <Separator />
                <Item onClick={(event) => handleItemClick(event)}>Send a File</Item>
                <Item onClick={(event) => openDigSoundListModal()}>Send a Dig Sound</Item>
                <Item onClick={(event) => handleItemClick(event)}>Send a Screen Capture </Item>
                <Submenu label="Share Screen With">
                    <Item onClick={(event) => handleItemClick(event)}>This User Only</Item>
                    <Item onClick={(event) => handleItemClick(event)}>All Users in this conversation</Item>
                </Submenu>
            </Menu>

            {
                changePasswordModalOpenSelector ?
                    <ChangePasswordModal
                        shouldShow={changePasswordModalOpenSelector}
                    /> : null
            }
            {
                findAndAddUserModalOpenSelector.isOpen ?
                    <FindAndAddUserModal
                        shouldShow={findAndAddUserModalOpenSelector.isOpen} />
                    : null
            }

            {
                showCreateRoomModal ?
                    <CreateRoomTwoModal
                        onClose={createRoomCloseModal}
                        shouldShow={showCreateRoomModal}
                        roomList={() => { }}
                    /> : null
            }
            {
                showRoomVideoUploadModal ?
                    <UploadRoomVideoModal
                        onClose={roomVideoUploadCloseModal}
                        shouldShow={showRoomVideoUploadModal}
                        roomId={parseInt(cryptr.decrypt(roomId))}
                        fromWhere='roomActionMenu'
                    /> : null
            }
            {
                showWhoIsViewingMyWebcamModal ?
                    <WhoIsViewingMeModal
                        onClose={whoIsViewingMeModalClose}
                        shouldShow={showWhoIsViewingMyWebcamModal}
                        roomId={parseInt(cryptr.decrypt(roomId))}
                    /> : null
            }

            {
                isOpenMultiRecipientMessageModal ?
                    <MultiRecipientMessageModal
                        shouldShow={isOpenMultiRecipientMessageModal}
                    /> : null
            }

            {
                joinChatRoomAsAdmin ?
                    <JoinChatRoomAsAdmin
                        onClose={joinChatAsAdminModalClose}
                        shouldShow={joinChatRoomAsAdmin}
                    /> : null

            }

            {changeMyAccount ?
                <ChangeAccount
                    onClose={changeMyAccountModalClose}
                    shouldShow={changeMyAccount}
                /> : null
            }

            {
                showPmUsersListModal ?
                    <PmUsersListModal
                        onClose={closePmUsersListModal}
                        shouldShow={showPmUsersListModal}
                        users={pmWindowDetailsSelector.users}
                    /> : null
            }

            {
                showDigSoundListModal ?
                    <DigSoundListModal
                        shouldShow={showDigSoundListModal}
                        onClose={closeDigSoundListModal}
                        pmId={pmWindowDetailsSelector.id}
                    /> : null
            }

            {
                showUpgradeRoomSubscriptionModal ?
                    <UpgradeRoomSubscriptionModal
                        shouldShow={showUpgradeRoomSubscriptionModal}
                        onClose={upgradeRoomSubscriptionCloseModal}
                        roomId={parseInt(cryptr.decrypt(roomId))}
                        onCancel={getRoomDetails}
                    /> : null
            }
            {
                showUpgradeNicknameSubscriptionModal ?
                    <UpgradeNicknameSubscriptionModal
                        shouldShow={showUpgradeNicknameSubscriptionModal}
                        onClose={upgradeNicknameSubscriptionCloseModal}
                        onCancel={userMeCall}
                    /> : null
            }
        </React.Fragment>

    );
};

export default ActionContextMenu;