import { API_BASE_URL, SOCKETURL } from './site_urls'

export const STORAGE = 'outright_ui'
export const LOGIN_STORAGE = {
  REMEMBER_NICKNAME: 'remember_nickname',
  LIST_OF_NICKNAME: 'list_of_nickname',
  LOGIN_AUTOMATICALLY: 'login_automatically'
}

export const USER_PREFERANCE_TAB = {
  ACTIVE_TAB: 'active_tab',
}


export const FILE_BASE_PATH = ''
export const APP_VERSION = process.env.REACT_APP_VERSION
export const APP_STAGE = process.env.REACT_APP_STAGE

export const DATE_FORMAT = 'MM/dd/yyyy';

export const DATE_ALL_FORMAT = {
  DATE_PICKER_FORMAT: 'MM/dd/yyyy',
  MOMENT_FORMAT: 'MM/DD/yyyy'
}

export const CHAT_DATE_TIME_FORMAT = {
  DISPLAY_DATE_WITH_TIME: 'Do MMMM, YYYY hh:mm a',
  DISPLAY_DAY_TIME: 'dddd hh:mm a'
}
export const TIME_CONFIG = {
  TIME_FORMAT: 'hh:mm a',
  TIME_INTERVALS: 5,
}

export const unscribePlayVideoTime = 10;


export const STATIC_URL = {
  USER: {
    AVATAR: 'http://ssl.gstatic.com/accounts/ui/avatar_2x.png'
  }
}

export const SOCKET_URL = SOCKETURL

export const URLS = {
  HOME: '/',
  JOIN_US: '/joinus',
  LOGIN: '/login',
  REGISTRATION: '/registration',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_OTP: '/forgot-password-otp',
  RESET_PASSWORD: '/reset-password',
  THANK_YOU: '/thankyou',
  USER: {
    DASHBOARD: '/dashboard',
    NOTEBOOK: '/notebook',
    MANAGE_VOICE_MAIL: '/manage-voice-mail',
    MANAGE_VIDEO_MESSAGE: '/manage-video-message',
    FIND_AND_JOIN_ROOM: '/find-and-join-room',
    GROUPS_AND_CATEGORY: '/groups',
    ROOMS: '/:groupId/rooms',
    ROOMS_DETAILS: '/:groupId/:roomId/room-details',
    MY_PROFILE: '/profile',
    USER_PREFERENCES: '/my-preferences',
    NEAR_BY_USER: '/nearby-users',
    MY_ROOMS: '/my-rooms',
    MANAGE_FAVOURITE_ROOMS: '/manage-favourite-rooms',
  },
  CMS: '/cms/:type/:slug',
  PM_WINDOW: {
    PM_ROOM: '/pm/:pmId'
  },
  SUBSCRIPTION: {
    ROOM_SUCCESS_CALLBACK: '/room/subscription/success',
    ROOM_FAILURE_CALLBACK: '/room/subscription/failure',
    NICKNAME_SUCCESS_CALLBACK: '/nickname/subscription/success',
    NICKNAME_FAILURE_CALLBACK: '/nickname/subscription/failure',
  },
  BUY_CREDIT: {
    CREDIT_SUCCESS_CALLBACK: '/virtual/credit/success',
    CREDIT_FAILURE_CALLBACK: '/virtual/credit/failure',
  }
}

export const API_URL = {
  USER: {
    LOGIN: `${API_BASE_URL}login`,
    GET_LOGIN_MODE: `${API_BASE_URL}login/mode`,
    REGISTRATION: `${API_BASE_URL}register`,
    CHECK_NICKNAME_AVAILABLITY: `${API_BASE_URL}check/nickname`,
    LOGIN_CHECK_PASSWORD: `${API_BASE_URL}users/check/password`,
    LOGOUT: `${API_BASE_URL}auth/logout`,
    DETAILS: `${API_BASE_URL}init`,
    FORGOT_PASSWORD_NICKNAME: `${API_BASE_URL}forgot/password`,
    FORGOT_PASSWORD_QUESTION_ANSWER: `${API_BASE_URL}forgot/password/question`,
    FORGOT_PASSWORD_OTP: `${API_BASE_URL}forgot/password/otp`,
    FORGOT_PASSWORD_RESET: `${API_BASE_URL}set/password`,
    UPDATE_PASSWORD: `${API_BASE_URL}users/password`,
    UPDATE_PROFILE_IMAGE: `${API_BASE_URL}users/avatar`,
    UPDATE_PROFILE_DETAILS: `${API_BASE_URL}users/update`,
    UPDATE_VISIBLE_STATUS: `${API_BASE_URL}users/change-my-status`,
    SHOW_PROFILE_PICTURE: `${API_BASE_URL}users/show-profile-picture`,
    FIND_AND_ADD_USER: `${API_BASE_URL}find/add/user`,
    CUSTOMIZED_PERSONAL_MESSAGE: `${API_BASE_URL}users/update/about-us`,
    CLEAR_ABOUT_MESSAGE: `${API_BASE_URL}users/clear/about-us`,
    SEND_MULTIRECIPIENT_MESSAGE: `${API_BASE_URL}pms/multi/recipient`,
    GROUPS_CATEGORY: {
      GROUP_CATEGORY_LIST: `${API_BASE_URL}rooms/category/list`,
      CREATE_ROOM: `${API_BASE_URL}rooms/create`,
      ROOM_LIST_CATEGORYWISE: `${API_BASE_URL}rooms/list`,
      GET_ROOM_TYPES: `${API_BASE_URL}rooms/type`,
      GET_ROOM_GROUPS: `${API_BASE_URL}rooms/group`,
      GET_ROOM_CATEGORY: `${API_BASE_URL}rooms/category`,
      GET_ROOM_LANGUAGE: `${API_BASE_URL}rooms/language`,
      JOIN_ROOM: `${API_BASE_URL}rooms/join`,
      EXIT_FROM_ROOM: `${API_BASE_URL}rooms/exit/room`,
      VERIFY_LOCKWORD: `${API_BASE_URL}rooms/verify/lockword`,
      VERIFY_ADMIN_CODE: `${API_BASE_URL}rooms/verify/admincode`,
      ADD_AS_FAVOURITE: `${API_BASE_URL}rooms/add/favourite`,
      REMOVE_FAVOURITE: `${API_BASE_URL}rooms/remove/favourite`,
      GET_ROOM_DETAILS: `${API_BASE_URL}rooms/details`,
      GET_MY_ACTIVE_ROOMS: `${API_BASE_URL}rooms/my/active/rooms`,
      MANAGE_TOPIC: `${API_BASE_URL}rooms/manage/topic`,

      LIKE_ROOM: `${API_BASE_URL}rooms/add/like`,
      REMOVE_LIKE_ROOM: `${API_BASE_URL}rooms/remove/like`,

      UPLOAD_ROOM_VIDEO: `${API_BASE_URL}rooms/upload/video`,

      UPDATE_ROOM: `${API_BASE_URL}rooms/update`,
      ROOM_VERIFY_ROOM_PASSWORD: `${API_BASE_URL}rooms/verify/roompassword`,
      DELETE_ROOM: `${API_BASE_URL}rooms/delete`,
      CLOSE_ROOM: `${API_BASE_URL}rooms/actions/close`,
      ROOM_ADMIN_LISTS: `${API_BASE_URL}rooms/admin/list`,
      ADD_ROOM_ADMIN: `${API_BASE_URL}rooms/add/admin`,
      DELETE_ROOM_ADMIN: `${API_BASE_URL}rooms/delete/admin`,

      VIEW_MY_WEBCAM: `${API_BASE_URL}rooms/view/video`,

      WHO_IS_VIEWING_MY_WEBCAM: `${API_BASE_URL}rooms/actions/who/viewing/me`,

      //admin control
      ROOM_ADMIN_CONTROL: `${API_BASE_URL}rooms/admin/control/panel`,
      ROOM_REMOVED_KICKED_USERS: `${API_BASE_URL}rooms/removed/kick/users`,
      ROOM_REMOVED_BANNED_USERS: `${API_BASE_URL}rooms/removed/banned/users`,
      APPLY_BANNED_TO_USER: `${API_BASE_URL}rooms/banned/users`,
      SAVE_ADMIN_CONTROL_SETTING: `${API_BASE_URL}rooms/admin/control/panel/save`,

      //room member
      CUSTOMIZED_NICKNAME: `${API_BASE_URL}rooms/actions/customize/nickname`,
      ADD_TO_CONTACT_LIST: `${API_BASE_URL}add/contactlist`,
      REMOVE_FROM_CONTACT_LIST: `${API_BASE_URL}remove/contactlist`,
      ADD_AS_FAVOURITE_CONTACT_LIST: `${API_BASE_URL}add/favourite/contactlist`,
      ADD_USER_AS_FAVOURITE_FROM_CONTACT: `${API_BASE_URL}add/favourite`, // Used for Global Left Menu Bar
      REMOVE_FROM_FAVOURITE_CONTACT_LIST: `${API_BASE_URL}remove/favouritelist`,
      KICK_USER_FROM_ROOM: `${API_BASE_URL}rooms/actions/kick/user`,
      REMOVE_HAND_FROM_ROOM: `${API_BASE_URL}rooms/actions/remove/hand`,
      RAISE_HAND_AT_ROOM: `${API_BASE_URL}rooms/actions/raise/hand`,
      RAISE_HAND_REMOVE_AT_ROOM: `${API_BASE_URL}rooms/actions/remove/raise/hand`,

      ADD_TO_BLOCK_LIST: `${API_BASE_URL}add/blocklist`,
      REMOVE_FROM_BLOCK_LIST: `${API_BASE_URL}remove/blocklist`,

      ADD_TO_IGNORE_LIST: `${API_BASE_URL}rooms/actions/add/ignore`,
      REMOVE_FROM_IGNORE_LIST: `${API_BASE_URL}rooms/actions/remove/ignore`,

      WEB_CAMERA_ON_OFF_TOGGLE: `${API_BASE_URL}rooms/is_camera_onoff`,

      CLEAR_MY_ROOM_CHAT: `${API_BASE_URL}rooms/actions/clear/text/chat`,

      //play a video
      ROOM_MENU_PLAY_VIDEO: `${API_BASE_URL}rooms/actions/play/upload/video`,
      ROOM_MENU_PLAY_ACCEPT_VIDEO: `${API_BASE_URL}rooms/actions/accept/play/video`,
      ROOM_MENU_PLAY_REJECT_VIDEO: `${API_BASE_URL}rooms/actions/reject/play/video`,
      ROOM_MENU_PLAY_REMOVE_VIDEO: `${API_BASE_URL}rooms/actions/remove/play/video`,


      CHAT: {
        GET_ALL_CHAT_FROM_ROOM: `${API_BASE_URL}chats/fetch-all`,
        POST_CHAT_IN_ROOM: `${API_BASE_URL}chats/post`
      },

      //Instant Invite to room
      INSTANT_INVITE_AT_ROOM: `${API_BASE_URL}rooms/invite/instant`,

      // join room as admin
      JOIN_ROOM_AS_ADMIN_ROOM_LIST: `${API_BASE_URL}rooms/actions/join/admin/list`,
      JOIN_ROOM_ADMIN_CODE_VERIFY: `${API_BASE_URL}rooms/actions/join/verify/admincode`,

      JOIN_SIMULTANEOUS_ROOM: `${API_BASE_URL}rooms/check/simultaneous`,
      SUBSCRIPTION: {
        SUBSCRIPTION_ROOM_LIST: `${API_BASE_URL}subscription/room/list`,
        SUBSCRIPTION_ROOM_BUY_PLAN: `${API_BASE_URL}subscription/room/process`,
        SUBSCRIPTION_ROOM_SUCCESS: `${API_BASE_URL}retrieve/checkout/session`,
        REMOVE_ROOM_SUBSCRIPTION: `${API_BASE_URL}subscription/room/remove`,
      },
      VIRTUAL_GIFT_CREDIT: {
        VIRTUAL_GIFT_CREDIT_LIST: `${API_BASE_URL}virtual/gift/credit/list`,
        VIRTUAL_GIFT_CREDIT_BUY: `${API_BASE_URL}virtual/gift/process`,
        VIRTUAL_GIFT_CREDIT_BUY_SUCCESS: `${API_BASE_URL}retrieve/virtual/gift/checkout/session`,
      }
    },
    MENU: {
      LEFT_SIDEBAR_LIST: `${API_BASE_URL}left/menu/list`,
      ADMIN_REMOVE_ALL_HAND: `${API_BASE_URL}rooms/actions/remove/all/hand`,
      ADMIN_DISABLE_INVITATION: `${API_BASE_URL}rooms/actions/disable/invitation`,

      GIVE_MIC_TO_ALL: `${API_BASE_URL}rooms/actions/give/mic/all`,
      REMOVE_ALL_MIC: `${API_BASE_URL}rooms/actions/remove/all/mic`,
      SIMULTENIOUS_MIC: `${API_BASE_URL}rooms/actions/simultaneous/mic`,
      GRAB_MIC: `${API_BASE_URL}rooms/actions/grab/mic`,
      CHANGE_USER_ROOM_SETTINGS: `${API_BASE_URL}rooms/actions/change/setting`,
      ROOM_SAVE_DEFAULT_SETTING: `${API_BASE_URL}rooms/actions/save/default/settings`,
      ROOM_RESET_DEFAULT_SETTING: `${API_BASE_URL}rooms/actions/reset/default/settings`,
      RED_DOT_FOR_ALL: `${API_BASE_URL}rooms/actions/red/dot/all`,
      RED_DOT_FOR_INDIVIDUAL_USER: `${API_BASE_URL}rooms/actions/red/dot/user`,


    },
    NEAR_BY_USER: `${API_BASE_URL}users/find/nearby`,
    FETCH_USER_DETAILS: `${API_BASE_URL}users/details`,
    UPDATE_CURRENT_LOCATION: `${API_BASE_URL}users/update/current/location`,
    FIND_AND_JOIN_ROOM: `${API_BASE_URL}rooms/find/join`,
    INVITE_TO_ROOM: `${API_BASE_URL}rooms/invite`,
    SUBSCRIPTION: {
      GET_NICKNAME_SUBSCRIPTION_PLAN: `${API_BASE_URL}subscription/nickname/list`,
      PROCESS_NICKNAME_SUBSCRIPTION: `${API_BASE_URL}subscription/nickname/process`,
      REMOVE_NICKNAME_SUBSCRIPTION: `${API_BASE_URL}subscription/nickname/remove`,
    }
  },
  VIDEO_VOICE_MESSAGE: {
    IS_PAGE_PASSWORD_PROTECTED: `${API_BASE_URL}vvmessage/is-page/password/protected`,
    CHECK_PASSWORD: `${API_BASE_URL}vvmessage/check/password`,
    GET_CONTACT_LIST: `${API_BASE_URL}vvmessage/contact/list`,
    SEND_MESSAGE: `${API_BASE_URL}vvmessage/send/message`,
    GET_MESSAGE_LIST: `${API_BASE_URL}vvmessage/list`,
    DELETE_MESSAGE: `${API_BASE_URL}vvmessage/delete/message`,
    RESTORE_MESSAGE: `${API_BASE_URL}vvmessage/restore/message`,
    VIEW_MESSAGE: `${API_BASE_URL}vvmessage/view/message`,
    CHECK_AVAILABILITY_USER: `${API_BASE_URL}vvmessage/check/users`,
  },
  NOTEBOOK: {
    NOTEBOOK_CONTACT_LIST: `${API_BASE_URL}notebook/contact/list`,
    NOTEBOOK_LIST: `${API_BASE_URL}notebook/list`,
    CREATE_NOTEBOOK: `${API_BASE_URL}notebook/create`,
    UPDATE_NOTEBOOK: `${API_BASE_URL}notebook/update`,
    SHARE_NOTEBOOK: `${API_BASE_URL}notebook/share`,
    NOTEBOOK_DETAILS: `${API_BASE_URL}notebook/details`,
    DELETE_NOTEBOOK: `${API_BASE_URL}notebook/delete`,
    REMOVE_NOTEBOOK_SHARE: `${API_BASE_URL}notebook/remove/share`,
    NOTEBOOK_IMAGE_UPLOAD: `${API_BASE_URL}notebook/upload/image`
  },
  USER_PREFERENCE: {
    GET_USER_ALL_PREFERENCE: `${API_BASE_URL}preferences/get-all`,
    SAVE_USER_PREFERENCE: `${API_BASE_URL}preferences/save`,
    GET_USER_AUTO_REPLY_MESSAGE: `${API_BASE_URL}preferences/get/auto/reply/message`,
    SAVE_USER_AUTO_REPLY_MESSAGE: `${API_BASE_URL}preferences/save/auto/message`,
    GET_ALL_ACCOUNT: `${API_BASE_URL}preferences/my/accounts`,
    DELETE_ACCOUNT: `${API_BASE_URL}preferences/delete/account`,
    RESTORE_ACCOUNT: `${API_BASE_URL}preferences/undo/account`,
    GET_USER_CONTACT_LIST: `${API_BASE_URL}preferences/contact/list`,
    GET_CONTACT_BLOCK_LIST_USER: `${API_BASE_URL}preferences/block/list`,
    REMOVE_ALL_CONTACT_FROM_LIST: `${API_BASE_URL}preferences/remove/contactlist/all`,
    REMOVE_ALL_CONTACT_BLOCK_LIST: `${API_BASE_URL}preferences/remove/blocklist/all`,
    UPLOAD_GALLERY_IMAGE: `${API_BASE_URL}preferences/upload/gallery`,
    GET_ALL_GALLERY_IMAGE: `${API_BASE_URL}preferences/all/gallery`,
    DELETE_GALLERY_IMAGE: `${API_BASE_URL}preferences/delete/gallery`,
    DELETE_ALL_GALLERY_IMAGE: `${API_BASE_URL}preferences/delete/all/gallery`,

    UPLOAD_CUSTOMIZED_SOUND: `${API_BASE_URL}preferences/upload/customize/sound`,
    GET_ALL_CUSTOMIZED_SOUND: `${API_BASE_URL}preferences/all/customize/sound`,
    DELETE_CUSTOMIZED_SOUND: `${API_BASE_URL}preferences/delete/customize/sound`,

    GET_PARENTAL_CONTROL_INFORMATION: `${API_BASE_URL}preferences/get-parental-info`,
    SAVE_PARENTAL_PREFERENCE_PASSWORD: `${API_BASE_URL}preferences/save/parental`,
    DELETE_PARENTAL_PREFERENCE: `${API_BASE_URL}preferences/delete/parental`,
    RESET_PARENTAL_PREFERENCE_PASSWORD: `${API_BASE_URL}preferences/reset/parental/password`,
    PARENTAL_FORGOT_PASSWORD_OTP: `${API_BASE_URL}preferences/forgot/password/otp`,
    PARENTAL_SET_PASSWORD: `${API_BASE_URL}preferences/set/parental/password`,


  },
  FAVOURITE_MENU: {
    MY_ROOM: `${API_BASE_URL}rooms/my/room`,
    MY_FAVOURITE_ROOM: `${API_BASE_URL}rooms/favourite/room`,
    FAVOURITE_FOLDER_LIST: `${API_BASE_URL}rooms/favourite/folder`,
    CREATE_FAVOURITE_FOLDER: `${API_BASE_URL}rooms/create/favourite/folder`,
    ASSIGN_ROOM_TO_FOLDER: `${API_BASE_URL}rooms/assign/favourite/folder`,
    RENAME_ROOM_NAME: `${API_BASE_URL}rooms/customize/room/name`,
    DELETE_FAVOURITE_FOLDER: `${API_BASE_URL}rooms/delete/favourite/folder`,
    LIST_OF_FAVOURITES_ROOM: `${API_BASE_URL}rooms/favourite/list`,
  },
  NOTIFICATION: {
    GET_ALL_NOTIFICATION: `${API_BASE_URL}notifications`,
    REMOVE_SINGLE_NOTIFICATION: `${API_BASE_URL}close/notification`,
  },
  PM_WINDOW: {
    SEND_PM: `${API_BASE_URL}pms/send`,
    PM_WINDOW_DETAILS: `${API_BASE_URL}pms/details`,
    ADD_MEMBER_INTO_PM_WINDOW: `${API_BASE_URL}pms/add`,
    REMOVE_MEMBER_FROM_PM_WINDOW: `${API_BASE_URL}pms/remove/users`,
    SEND_CHAT: `${API_BASE_URL}pms/send/chat`,
    GET_CHAT: `${API_BASE_URL}pms/get/chat`,
    ACTIVE_PM_WINDOW: `${API_BASE_URL}pms/iam/in`,
    SHOW_USERS_LOCATION: `${API_BASE_URL}pms/user/location`,
    PM_CALL_INITIATE_AND_DISCONNECT: `${API_BASE_URL}pms/video/audio/onoff`,
    PM_CALL_ACCEPT_REJECT: `${API_BASE_URL}pms/acccept/audio/video`,
    EXIT_PM_WINDOW: `${API_BASE_URL}pms/exit`,
    PM_WINDOW_HEADER_MENU_SETTING_ACTION_UPDATE: `${API_BASE_URL}pms/actions/change/setting`,
    PM_WINDOW_HEADER_MENU_SAVE_DEFAULT_SETTING: `${API_BASE_URL}pms/actions/save/default/settings`,
    PM_WINDOW_HEADER_MENU_RESET_DEFAULT_SETTING: `${API_BASE_URL}pms/actions/reset/default/settings`,
    PM_READ: `${API_BASE_URL}pms/read`,
    GET_DIG_SOUND_LIST: `${API_BASE_URL}pms/actions/dig/sound/list`,
    SEND_DIG_SOUND: `${API_BASE_URL}pms/actions/dig/sound/send`,
  },
  COMMON: {
    GENDER_LIST: `${API_BASE_URL}genders`,
    COUNTRY_LIST: `${API_BASE_URL}country/list`,
    SECRET_QUESTION_LIST: `${API_BASE_URL}secret/questions`,
    ONLINE_OFFLINE_CONTACT_LIST: `${API_BASE_URL}contact/list`,
    CHAT_STICKER_CATEGORIES: `${API_BASE_URL}stickers/categories`,
    GET_CATEGORYWISE_ALL_STICKER: `${API_BASE_URL}stickers/get/all`,
    STICKER_BUY: `${API_BASE_URL}stickers/buy`,
    STICKER_OWN: `${API_BASE_URL}stickers/own`,
    STICKER_GIFT_PURCHASE: `${API_BASE_URL}stickers/send/gift`,
    CMS: `${API_BASE_URL}cms-contents`,
  }

}

export const ACTIONS = {
  LOADER: {
    SET_FP_STATUS: 'ACTIONS/LOADER/SET_FP_STATUS',
  },
  GLOBAL: {
    SET_LOGIN_MODAL: 'ACTIONS/GLOBAL/SET_LOGIN_MODAL',
  },
  FORGOT_PASSWORD: {
    FORGOT_PASSWORD_STEP: 'ACTIONS/FORGOT_PASSWORD/FORGOT_PASSWORD_STEP',
    FORGOT_PASSWORD_DATA: 'ACTIONS/FORGOT_PASSWORD/FORGOT_PASSWORD_DATA',
  },
  REGISTRATION: {
    REGISTRATION_STEP: 'ACTIONS/REGISTRATION/REGISTRATION_STEP',
    REGISTRATION_DATA: 'ACTIONS/REGISTRATION/REGISTRATION_DATA',
  },
  USER: {
    LOGIN: 'ACTIONS/USER/LOGIN',
    ME: 'ACTIONS/USER/ME',
    ONLINE_STATUS_CHANGE: 'ACTIONS/USER/ONLINE_STATUS_CHANGE',
    SOCKET_INSTANCE_CONTAINER: 'ACTIONS/USER/SOCKET_INSTANCE_CONTAINER',
    LOGOUT: 'ACTIONS/USER/LOGOUT',
    PROFILE: 'ACTIONS/USER/PROFILE',
    MANAGE_ABOUT_MESSAGE: 'ACTIONS/USER/MANAGE_ABOUT_MESSAGE',
    GROUPS_CATEGORY: {
      TOTAL_ONLINE_USERS: 'ACTIONS/USER/TOTAL_ONLINE_USERS',
      GROUP_CATEGORY_LIST: 'ACTIONS/ROOM/GROUP_CATEGORY_LIST',
      ROOM_LIST_CATEGORYWISE: 'ACTIONS/ROOM/ROOM_LIST_CATEGORYWISE',
      GET_ROOM_TYPES: 'ACTIONS/ROOM/GET_ROOM_TYPES',
      MY_ACTIVE_ROOMS_ISOPEN_HANDLER: 'ACTIONS/ROOM/MY_ACTIVE_ROOMS_ISOPEN_HANDLER',
      MY_ACTIVE_ROOMS_FROM_ROUTE: 'ACTIONS/ROOM/MY_ACTIVE_ROOMS_FROM_ROUTE',
      GET_ROOM_GROUPS: 'ACTIONS/ROOM/GET_ROOM_GROUPS',
      GET_ROOM_CATEGORY: 'ACTIONS/ROOM/GET_ROOM_CATEGORY',
      GET_ROOM_DETAILS: 'ACTIONS/ROOM/GET_ROOM_DETAILS',
      GET_MY_ACTIVE_ROOMS: 'ACTIONS/ROOM/GET_MY_ACTIVE_ROOMS',
      ROOM_OTHER_MEMBERS: 'ACTIONS/ROOM/ROOM_OTHER_MEMBERS',
      KICK_USER: 'ACTIONS/ROOM/KICK_USER',
      ROOM_USER_VIDEO_URL_UPDATE: 'ACTIONS/GROUPS_CATEGORY/ROOM_USER_VIDEO_URL_UPDATE',
      ROOM_USER_VIDEO_URL_EMPTY: 'ACTIONS/GROUPS_CATEGORY/ROOM_USER_VIDEO_URL_EMPTY',
      //admin control
      ROOM_ADMIN_CONTROL: 'ACTIONS/ROOM/ADMIN_CONTROL/ROOM_ADMIN_CONTROL',

      CHAT: {
        GET_ALL_CHAT_FROM_ROOM: 'ACTIONS/GROUPS_CATEGORY/CHAT/GET_ALL_CHAT_FROM_ROOM',
        POST_CHAT_IN_ROOM: 'ACTIONS/GROUPS_CATEGORY/CHAT/POST_CHAT_IN_ROOM',
        GET_SOCKET_DATA_AND_PUSH_TO_CHAT_LIST: 'ACTIONS/GROUPS_CATEGORY/CHAT/GET_SOCKET_DATA_AND_PUSH_TO_CHAT_LIST',
        UPDATE_ROOM_CHAT_TOPIC: 'ACTIONS/GROUPS_CATEGORY/CHAT/UPDATE_ROOM_CHAT_TOPIC',
        SELECT_DESELECT_CHAT_DATA: 'ACTIONS/GROUPS_CATEGORY/CHAT/SELECT_DESELECT_CHAT_DATA',
      },
      UPDATE_ROOM_MEMBER_CAM_STATUS_FROM_OPENTOK_STREAMS: 'ACTIONS/GROUPS_CATEGORY/UPDATE_ROOM_MEMBER_CAM_STATUS_FROM_OPENTOK_STREAMS',
      INSTANCE_INVITE_AT_ROOM: 'ACTIONS/GROUPS_CATEGORY/ROOM/INSTANCE_INVITE_AT_ROOM',

      GRAB_AND_RELEASE_MIC: 'ACTIONS/GROUPS_CATEGORY/ROOM/GRAB_AND_RELEASE_MIC',

      //play a video
      ROOM_MENU_PLAY_VIDEO: 'ACTIONS/GROUPS_CATEGORY/ROOM/ROOM_MENU_PLAY_VIDEO',

      //Empty Close room Redicer value
      EMPTY_CLOSE_ROOM_VALUE: 'ACTIONS/GROUPS_CATEGORY/ROOM/EMPTY_CLOSE_ROOM_VALUE',

    },
    MENU: {
      GET_LEFT_SIDEBAR_LIST: 'ACTIONS/USER/MENU/GET_SIDEBAR_LIST',
      GET_LEFT_SIDEBAR_SOCKET_DATA_AND_PUSH_TO_LIST: 'ACTIONS/USER/MENU/GET_LEFT_SIDEBAR_SOCKET_DATA_AND_PUSH_TO_LIST',
      ROOM_SETTING_MENU: 'ACTIONS/USER/MENU/ROOM_SETTING_MENU',
      ROOM_ADMIN_CONTROL_MENU: 'ACTIONS/USER/MENU/ROOM_ADMIN_CONTROL',
      CHANGE_PASSWORD_MENU: 'ACTIONS/USER/MENU/CHANGE_PASSWORD',
      GET_SOCKET_DATA_AND_PUSH_TO_ROOM_DETAILS_SIDEBAR_LIST: 'ACTIONS/USER/MENU/GET_SOCKET_DATA_AND_PUSH_TO_ROOM_DETAILS_SIDEBAR_LIST',
      ADMIN_REMOVE_ALL_HAND: 'ACTIONS/USER/MENU/ADMIN_REMOVE_ALL_HAND',
      ADMIN_DISABLE_INVITATION_SOCKET: 'ACTIONS/USER/MENU/ADMIN_DISABLE_INVITATION_SOCKET',
      ADMIN_MIC_MENU_HANDLE_SOCKET: 'ACTIONS/USER/MENU/ADMIN_MIC_MENU_HANDLE_SOCKET',
      NORMAL_USER_GRAB_MIC_ENABLE_MIC_OPTION: 'ACTIONS/USER/MENU/NORMAL_USER_GRAB_MIC_ENABLE_MIC_OPTION',
      NORMAL_USER_MIC_HANDLE_IS_CHANGED_RESET: 'ACTIONS/USER/MENU/NORMAL_USER_MIC_HANDLE_IS_CHANGED_RESET',

      ROOM_CHAT_TIMESTAMP_TOOGLE: 'ACTIONS/USER/MENU/ROOM_CHAT_TIMESTAMP_TOOGLE',
      RED_DOT_SOCKET_DATA_APPLY_TO_ALL: 'ACTIONS/USER/MENU/RED_DOT_SOCKET_DATA_APPLY_TO_ALL'

    },
    FIND_AND_JOIN_ROOM: 'ACTIONS/USER/FIND_AND_JOIN_ROOM',
    SHARE_WITH_OTHER_CONTACT: 'ACTIONS/USER/SHARE_WITH_OTHER_CONTACT',
    LOGGEDIN_FROM_OTHER_LOCATION: 'ACTIONS/USER/LOGGEDIN_FROM_OTHER_LOCATION',
    SHOW_MULTI_RECIPIENT_MESSAGE_MODAL: 'ACTIONS/USER/SHOW_MULTI_RECIPIENT_MESSAGE_MODAL',
  },
  VIDEO_VOICE_MESSAGE: {
    VIDEO_MESSAGE_MODAL: 'ACTIONS/VIDEO_MESSAGE/VIDEO_MESSAGE_MODAL',
    VOICE_MAIL_MODAL: 'ACTIONS/VOICE_MAIL/VOICE_MAIL_MODAL',
    GET_MESSAGE_LIST: 'ACTIONS/VIDEO_MESSAGE/GET_MESSAGE_LIST',
    UPDATE_VIDEO_MESSAGE_LIST: 'ACTIONS/VIDEO_MESSAGE/UPDATE_VIDEO_MESSAGE_LIST',
    UPDATE_VOICE_MESSAGE_LIST: 'ACTIONS/VIDEO_MESSAGE/UPDATE_VOICE_MESSAGE_LIST',
    GET_CONTACT_LIST_USER: 'ACTIONS/VIDEO_MESSAGE/GET_CONTACT_LIST_USER',
  },
  NOTEBOOK: {
    GET_NOTEBOOK_LIST: 'ACTIONS/NOTEBOOK/GET_NOTEBOOK_LIST',
    GET_NOTEBOOK_CONTACT_LIST: 'ACTIONS/NOTEBOOK/GET_NOTEBOOK_CONTACT_LIST',
    GET_NOTEBOOK_DETAILS: 'ACTIONS/NOTEBOOK/GET_NOTEBOOK_DETAILS',
    UPDATE_NOTEBOOK_LIST: 'ACTIONS/NOTEBOOK/UPDATE_NOTEBOOK_LIST',
    VIDEO_VOICE_NOTEBOOK_COUNT: 'ACTIONS/NOTEBOOK/VIDEO_VOICE_NOTEBOOK_COUNT',
  },
  FAVOURITE_MENU: {
    GET_ROOM_LIST: 'ACTIONS/FAVOURITE_MENU/GET_ROOM_LIST',
    GET_FAVOURITE_FOLDER_LIST: 'ACTIONS/FAVOURITE_MENU/GET_FAVOURITE_FOLDER_LIST',
    LIST_OF_FAVOURITES_ROOM: 'ACTIONS/FAVOURITE_MENU/LIST_OF_FAVOURITES_ROOM',
  },
  USER_PREFERENCE: {
    GET_USER_ALL_PREFERENCE: 'ACTIONS/USER_PREFERENCE/GET_USER_ALL_PREFERENCE',
    FIND_AND_ADD_USER: 'ACTIONS/USER_PREFERENCE/FIND_AND_ADD_USER',
  },
  NOTIFICATION: {
    GET_ALL_NOTIFICATION: 'ACTIONS/NOTIFICATION/GET_ALL_NOTIFICATION',
    REMOVE_NOTIFICATION_SINGLE: 'ACTIONS/NOTIFICATION/REMOVE_NOTIFICATION_SINGLE'
  },
  PM_WINDOW: {
    SEND_PM: 'ACTIONS/PM_WINDOW/SEND_PM',
    GET_CHAT: 'ACTIONS/PM_WINDOW/GET_CHAT',
    MY_ACTIVE_PM_WINDOW_ISOPEN_HANDLER: 'ACTIONS/PM_WINDOW/MY_ACTIVE_PM_WINDOW_ISOPEN_HANDLER',
    GET_MY_ACTIVE_PM_WINDOWS: 'ACTIONS/PM_WINDOW/GET_MY_ACTIVE_PM_WINDOWS',
    ADD_REMOVE_USERS: 'ACTIONS/PM_WINDOW/ADD_REMOVE_USERS',
    MY_ACTIVE_PMS_FROM_ROUTE: 'ACTIONS/PM_WINDOW/MY_ACTIVE_PMS_FROM_ROUTE',
    TYPEING_NOTIFICATION: 'ACTIONS/PM_WINDOW/TYPEING_NOTIFICATION',
    RECENT_PMS_LIST: 'ACTIONS/PM_WINDOW/RECENT_PMS_LIST',
    DIG_SOUND: 'ACTIONS/PM_WINDOW/DIG_SOUND',
    MENU: {
      PM_CHAT_TIMESTAMP_TOOGLE: 'ACTIONS/PM_WINDOW/MENU/PM_CHAT_TIMESTAMP_TOOGLE',
    },
    CHAT: {
      GET_SOCKET_DATA_AND_PUSH_TO_CHAT_LIST: 'ACTIONS/PM_WINDOW/CHAT/GET_SOCKET_DATA_AND_PUSH_TO_CHAT_LIST',
      SELECT_DESELECT_PM_CHAT_DATA: 'ACTIONS/PM_WINDOW/CHAT/SELECT_DESELECT_PM_CHAT_DATA',
    },
    CALL: {
      INCOMING_CALL_DETAILS: 'ACTIONS/PM_WINDOW/CALL/INCOMING_CALL_DETAILS',
    }
  },
}

export const SAGA_ACTIONS = {
  USER: {
    LOGIN: 'SAGA_ACTIONS/USER/LOGIN',
    GET_LOGIN_MODE: 'SAGA_ACTIONS/USER/GET_LOGIN_MODE',
    LOGIN_CHECK_PASSWORD: 'SAGA_ACTIONS/USER/LOGIN_CHECK_PASSWORD',
    LOGOUT: 'SAGA_ACTIONS/USER/LOGOUT',
    DETAILS: 'SAGA_ACTIONS/USER/DETAILS',
    CHECK_NICKNAME_AVAILABLITY: 'SAGA_ACTIONS/USER/CHECK_NICKNAME_AVAILABLITY',
    REGISTRATION: 'SAGA_ACTIONS/USER/REGISTRATION',
    FORGOT_PASSWORD_NICKNAME: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD_NICKNAME',
    FORGOT_PASSWORD_QUESTION_ANSWER: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD_QUESTION_ANSWER',
    FORGOT_PASSWORD_OTP: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD_OTP',
    FORGOT_PASSWORD_RESET: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD_RESET',
    UPDATE_PASSWORD: 'SAGA_ACTIONS/USER/UPDATE_PASSWORD',
    UPDATE_PROFILE_IMAGE: 'SAGA_ACTIONS/USER/UPDATE_PROFILE_IMAGE',
    UPDATE_PROFILE_DETAILS: 'SAGA_ACTIONS/USER/UPDATE_PROFILE_DETAILS',
    UPDATE_VISIBLE_STATUS: 'SAGA_ACTIONS/USER/UPDATE_VISIBLE_STATUS',
    SHOW_PROFILE_PICTURE: 'SAGA_ACTIONS/USER/SHOW_PROFILE_PICTURE',
    FIND_AND_ADD_USER: 'SAGA_ACTIONS/USER/FIND_AND_ADD_USER',
    CUSTOMIZED_PERSONAL_MESSAGE: 'SAGA_ACTIONS/USER/CUSTOMIZED_PERSONAL_MESSAGE',
    CLEAR_ABOUT_MESSAGE: 'SAGA_ACTIONS/USER/CLEAR_ABOUT_MESSAGE',
    SEND_MULTIRECIPIENT_MESSAGE: 'SAGA_ACTIONS/USER/SEND_MULTIRECIPIENT_MESSAGE',
    GROUPS_CATEGORY: {
      GROUP_CATEGORY_LIST: 'SAGA_ACTIONS/ROOM/GROUP_CATEGORY_LIST',
      CREATE_ROOM: 'SAGA_ACTIONS/ROOM/CREATE_ROOM',
      ROOM_LIST_CATEGORYWISE: 'SAGA_ACTIONS/ROOM/ROOM_LIST_CATEGORYWISE',
      GET_ROOM_TYPES: 'SAGA_ACTIONS/ROOM/GET_ROOM_TYPES',
      GET_ROOM_GROUPS: 'SAGA_ACTIONS/ROOM/GET_ROOM_GROUPS',
      GET_ROOM_CATEGORY: 'SAGA_ACTIONS/ROOM/GET_ROOM_CATEGORY',
      GET_ROOM_LANGUAGE: 'SAGA_ACTIONS/ROOM/GET_ROOM_LANGUAGE',
      JOIN_ROOM: 'SAGA_ACTIONS/ROOM/JOIN_ROOM',
      EXIT_FROM_ROOM: 'SAGA_ACTIONS/ROOM/EXIT_FROM_ROOM',
      VERIFY_LOCKWORD: 'SAGA_ACTIONS/ROOM/VERIFY_LOCKWORD',
      VERIFY_ADMIN_CODE: 'SAGA_ACTIONS/ROOM/VERIFY_ADMIN_CODE',
      ADD_AS_FAVOURITE: 'SAGA_ACTIONS/ROOM/ADD_AS_FAVOURITE',
      REMOVE_FAVOURITE: 'SAGA_ACTIONS/ROOM/REMOVE_FAVOURITE',
      GET_ROOM_DETAILS: 'SAGA_ACTIONS/ROOM/GET_ROOM_DETAILS',
      GET_MY_ACTIVE_ROOMS: 'SAGA_ACTIONS/ROOM/GET_MY_ACTIVE_ROOMS',
      MANAGE_TOPIC: 'SAGA_ACTIONS/ROOM/MANAGE_TOPIC',

      LIKE_ROOM: 'SAGA_ACTIONS/ROOM/LIKE_ROOM',
      REMOVE_LIKE_ROOM: 'SAGA_ACTIONS/ROOM/REMOVE_LIKE_ROOM',

      UPLOAD_ROOM_VIDEO: 'SAGA_ACTIONS/ROOM/UPLOAD_ROOM_VIDEO',

      UPDATE_ROOM: 'SAGA_ACTIONS/ROOM/UPDATE_ROOM',
      ROOM_VERIFY_ROOM_PASSWORD: 'SAGA_ACTIONS/ROOM/ROOM_VERIFY_ROOM_PASSWORD',

      DELETE_ROOM: 'SAGA_ACTIONS/ROOM/DELETE_ROOM',
      CLOSE_ROOM: 'SAGA_ACTIONS/ROOM/CLOSE_ROOM',
      ROOM_ADMIN_LISTS: 'SAGA_ACTIONS/ROOM/ROOM_ADMIN_LISTS',
      ADD_ROOM_ADMIN: 'SAGA_ACTIONS/ROOM/ADD_ROOM_ADMIN',
      DELETE_ROOM_ADMIN: 'SAGA_ACTIONS/ROOM/DELETE_ROOM_ADMIN',
      VIEW_MY_WEBCAM: 'SAGA_ACTIONS/ROOM/VIEW_MY_WEBCAM',

      WHO_IS_VIEWING_MY_WEBCAM: 'SAGA_ACTIONS/ROOM/WHO_IS_VIEWING_MY_WEBCAM',

      //admin control
      ROOM_ADMIN_CONTROL: 'SAGA_ACTIONS/ROOM/ADMIN_CONTROL/ROOM_ADMIN_CONTROL',
      ROOM_REMOVED_KICKED_USERS: 'SAGA_ACTIONS/ROOM/ADMIN_CONTROL/ROOM_REMOVED_KICKED_USERS',
      ROOM_REMOVED_BANNED_USERS: 'SAGA_ACTIONS/ROOM/ADMIN_CONTROL/ROOM_REMOVED_BANNED_USERS',
      APPLY_BANNED_TO_USER: 'SAGA_ACTIONS/ROOM/ADMIN_CONTROL/APPLY_BANNED_TO_USER',
      SAVE_ADMIN_CONTROL_SETTING: 'SAGA_ACTIONS/ROOM/ADMIN_CONTROL/SAVE_ADMIN_CONTROL_SETTING',

      //room member
      CUSTOMIZED_NICKNAME: 'SAGA_ACTIONS/ROOM/CUSTOMIZED_NICKNAME',
      ADD_TO_CONTACT_LIST: 'SAGA_ACTIONS/ROOM/ADD_TO_CONTACT_LIST',
      REMOVE_FROM_CONTACT_LIST: 'SAGA_ACTIONS/ROOM/REMOVE_FROM_CONTACT_LIST',
      ADD_AS_FAVOURITE_CONTACT_LIST: 'SAGA_ACTIONS/ROOM/ADD_AS_FAVOURITE_CONTACT_LIST',
      ADD_USER_AS_FAVOURITE_FROM_CONTACT: 'SAGA_ACTIONS/ROOM/ADD_USER_AS_FAVOURITE_FROM_CONTACT',
      REMOVE_FROM_FAVOURITE_CONTACT_LIST: 'SAGA_ACTIONS/ROOM/REMOVE_FROM_FAVOURITE_CONTACT_LIST',
      KICK_USER_FROM_ROOM: 'SAGA_ACTIONS/ROOM/KICK_USER_FROM_ROOM',
      REMOVE_HAND_FROM_ROOM: 'SAGA_ACTIONS/ROOM/REMOVE_HAND_FROM_ROOM',
      RAISE_HAND_AT_ROOM: 'SAGA_ACTIONS/ROOM/RAISE_HAND_AT_ROOM',
      RAISE_HAND_REMOVE_AT_ROOM: 'SAGA_ACTIONS/ROOM/RAISE_HAND_REMOVE_AT_ROOM',

      ADD_TO_BLOCK_LIST: 'SAGA_ACTIONS/ROOM/ADD_TO_BLOCK_LIST',
      REMOVE_FROM_BLOCK_LIST: 'SAGA_ACTIONS/ROOM/REMOVE_FROM_BLOCK_LIST',

      ADD_TO_IGNORE_LIST: 'SAGA_ACTIONS/ROOM/ADD_TO_IGNORE_LIST',
      REMOVE_FROM_IGNORE_LIST: 'SAGA_ACTIONS/ROOM/REMOVE_FROM_IGNORE_LIST',

      WEB_CAMERA_ON_OFF_TOGGLE: 'SAGA_ACTIONS/ROOM/WEB_CAMERA_ON_OFF_TOGGLE',

      CLEAR_MY_ROOM_CHAT: 'SAGA_ACTIONS/ROOM/CLEAR_MY_ROOM_CHAT',

      //play a video
      ROOM_MENU_PLAY_VIDEO: 'SAGA_ACTIONS/ROOM/ROOM_MENU_PLAY_VIDEO',
      ROOM_MENU_PLAY_ACCEPT_VIDEO: 'SAGA_ACTIONS/ROOM/ROOM_MENU_PLAY_ACCEPT_VIDEO',
      ROOM_MENU_PLAY_REJECT_VIDEO: 'SAGA_ACTIONS/ROOM/ROOM_MENU_PLAY_REJECT_VIDEO',
      ROOM_MENU_PLAY_REMOVE_VIDEO: 'SAGA_ACTIONS/ROOM/ROOM_MENU_PLAY_REMOVE_VIDEO',

      CHAT: {
        GET_ALL_CHAT_FROM_ROOM: 'SAGA_ACTIONS/GROUPS_CATEGORY/CHAT/GET_ALL_CHAT_FROM_ROOM',
        POST_CHAT_IN_ROOM: 'SAGA_ACTIONS/GROUPS_CATEGORY/CHAT/POST_CHAT_IN_ROOM'
      },

      //INSTANT INVITE
      INSTANT_INVITE_AT_ROOM: 'SAGA_ACTIONS/GROUPS_CATEGORY/ROOM/INSTANT_INVITE_AT_ROOM',

      JOIN_ROOM_AS_ADMIN_ROOM_LIST: 'SAGA_ACTIONS/GROUPS_CATEGORY/JOIN_ROOM_AS_ADMIN_ROOM_LIST',
      JOIN_ROOM_ADMIN_CODE_VERIFY: 'SAGA_ACTIONS/GROUPS_CATEGORY/JOIN_ROOM_ADMIN_CODE_VERIFY',

      JOIN_SIMULTANEOUS_ROOM: 'SAGA_ACTIONS/GROUPS_CATEGORY/JOIN_SIMULTANEOUS_ROOM',

      SUBSCRIPTION: {
        SUBSCRIPTION_ROOM_LIST: 'SAGA_ACTIONS/GROUPS_CATEGORY/SUBSCRIPTION/SUBSCRIPTION_ROOM_LIST',
        SUBSCRIPTION_ROOM_BUY_PLAN: 'SAGA_ACTIONS/GROUPS_CATEGORY/SUBSCRIPTION/SUBSCRIPTION_ROOM_BUY_PLAN',
        SUBSCRIPTION_ROOM_SUCCESS: 'SAGA_ACTIONS/GROUPS_CATEGORY/SUBSCRIPTION/SUBSCRIPTION_ROOM_SUCCESS',
        REMOVE_ROOM_SUBSCRIPTION: 'SAGA_ACTIONS/GROUPS_CATEGORY/SUBSCRIPTION/REMOVE_ROOM_SUBSCRIPTION',
      },
      VIRTUAL_GIFT_CREDIT: {
        VIRTUAL_GIFT_CREDIT_LIST: 'SAGA_ACTIONS/GROUPS_CATEGORY/VIRTUAL_GIFT_CREDIT/VIRTUAL_GIFT_CREDIT_LIST',
        VIRTUAL_GIFT_CREDIT_BUY: 'SAGA_ACTIONS/GROUPS_CATEGORY/VIRTUAL_GIFT_CREDIT/VIRTUAL_GIFT_CREDIT_BUY',
        VIRTUAL_GIFT_CREDIT_BUY_SUCCESS: 'SAGA_ACTIONS/GROUPS_CATEGORY/VIRTUAL_GIFT_CREDIT/VIRTUAL_GIFT_CREDIT_BUY_SUCCESS'
      }

    },
    MENU: {
      LEFT_SIDEBAR_LIST: 'SAGA_ACTIONS/USER/MENU/LEFT_SIDEBAR_LIST',
      ADMIN_REMOVE_ALL_HAND: 'SAGA_ACTIONS/USER/MENU/ADMIN_REMOVE_ALL_HAND',
      ADMIN_DISABLE_INVITATION: 'SAGA_ACTIONS/USER/MENU/ADMIN_DISABLE_INVITATION',

      GIVE_MIC_TO_ALL: 'SAGA_ACTIONS/USER/MENU/GIVE_MIC_TO_ALL',
      REMOVE_ALL_MIC: 'SAGA_ACTIONS/USER/MENU/REMOVE_ALL_MIC',
      SIMULTENIOUS_MIC: 'SAGA_ACTIONS/USER/MENU/SIMULTENIOUS_MIC',
      GRAB_MIC: 'SAGA_ACTIONS/USER/MENU/GRAB_MIC',
      CHANGE_USER_ROOM_SETTINGS: 'SAGA_ACTIONS/ROOM/CHANGE_USER_ROOM_SETTINGS',
      ROOM_SAVE_DEFAULT_SETTING: 'SAGA_ACTIONS/ROOM/ROOM_SAVE_DEFAULT_SETTING',
      ROOM_RESET_DEFAULT_SETTING: 'SAGA_ACTIONS/ROOM/ROOM_RESET_DEFAULT_SETTING',
      RED_DOT_FOR_ALL: 'SAGA_ACTIONS/ROOM/RED_DOT_FOR_ALL',
      RED_DOT_FOR_INDIVIDUAL_USER: 'SAGA_ACTIONS/ROOM/RED_DOT_FOR_INDIVIDUAL_USER',


    },
    NEAR_BY_USER: 'SAGA_ACTIONS/USER/FIND/NEAR_BY_USER',
    FETCH_USER_DETAILS: 'SAGA_ACTIONS/USER/FETCH_USER_DETAILS',
    UPDATE_CURRENT_LOCATION: 'SAGA_ACTIONS/USER/UPDATE_CURRENT_LOCATION',
    FIND_AND_JOIN_ROOM: 'SAGA_ACTIONS/USER/FIND_AND_JOIN_ROOM',
    INVITE_TO_ROOM: 'SAGA_ACTIONS/USER/INVITE_TO_ROOM',
    SUBSCRIPTION: {
      GET_NICKNAME_SUBSCRIPTION_PLAN: 'SAGA_ACTIONS/USER/GET_NICKNAME_SUBSCRIPTION_PLAN',
      PROCESS_NICKNAME_SUBSCRIPTION: 'SAGA_ACTIONS/USER/PROCESS_NICKNAME_SUBSCRIPTION',
      REMOVE_NICKNAME_SUBSCRIPTION: 'SAGA_ACTIONS/USER/REMOVE_NICKNAME_SUBSCRIPTION',
    }
  },
  VIDEO_VOICE_MESSAGE: {
    IS_PAGE_PASSWORD_PROTECTED: 'SAGA_ACTIONS/VIDEO_VOICE_MESSAGE/IS_PAGE_PASSWORD_PROTECTED',
    CHECK_PASSWORD: 'SAGA_ACTIONS/VIDEO_VOICE_MESSAGE/CHECK_PASSWORD',
    GET_CONTACT_LIST: 'SAGA_ACTIONS/VIDEO_VOICE_MESSAGE/GET_CONTACT_LIST',
    SEND_MESSAGE: 'SAGA_ACTIONS/VIDEO_VOICE_MESSAGE/SEND_MESSAGE',
    GET_MESSAGE_LIST: 'SAGA_ACTIONS/VIDEO_VOICE_MESSAGE/GET_MESSAGE_LIST',
    DELETE_MESSAGE: 'SAGA_ACTIONS/VIDEO_VOICE_MESSAGE/DELETE_MESSAGE',
    RESTORE_MESSAGE: 'SAGA_ACTIONS/VIDEO_VOICE_MESSAGE/RESTORE_MESSAGE',
    VIEW_MESSAGE: 'SAGA_ACTIONS/VIDEO_VOICE_MESSAGE/VIEW_MESSAGE',
    CHECK_AVAILABILITY_USER: 'SAGA_ACTIONS/VIDEO_VOICE_MESSAGE/CHECK_AVAILABILITY_USER',
  },
  NOTEBOOK: {
    NOTEBOOK_CONTACT_LIST: 'SAGA_ACTIONS/NOTEBOOK/NOTEBOOK_CONTACT_LIST',
    NOTEBOOK_LIST: 'SAGA_ACTIONS/NOTEBOOK/NOTEBOOK_LIST',
    CREATE_NOTEBOOK: 'SAGA_ACTIONS/NOTEBOOK/CREATE_NOTEBOOK',
    UPDATE_NOTEBOOK: 'SAGA_ACTIONS/NOTEBOOK/UPDATE_NOTEBOOK',
    SHARE_NOTEBOOK: 'SAGA_ACTIONS/NOTEBOOK/SHARE_NOTEBOOK',
    NOTEBOOK_DETAILS: 'SAGA_ACTIONS/NOTEBOOK/NOTEBOOK_DETAILS',
    DELETE_NOTEBOOK: 'SAGA_ACTIONS/NOTEBOOK/DELETE_NOTEBOOK',
    REMOVE_NOTEBOOK_SHARE: 'SAGA_ACTIONS/NOTEBOOK/REMOVE_NOTEBOOK_SHARE',
  },
  USER_PREFERENCE: {
    GET_USER_ALL_PREFERENCE: 'SAGA_ACTIONS/USER_PREFERENCE/GET_USER_ALL_PREFERENCE',
    SAVE_USER_PREFERENCE: 'SAGA_ACTIONS/USER_PREFERENCE/SAVE_USER_PREFERENCE',
    GET_USER_AUTO_REPLY_MESSAGE: 'SAGA_ACTIONS/USER_PREFERENCE/GET_USER_AUTO_REPLY_MESSAGE',
    SAVE_USER_AUTO_REPLY_MESSAGE: 'SAGA_ACTIONS/USER_PREFERENCE/SAVE_USER_AUTO_REPLY_MESSAGE',
    GET_ALL_ACCOUNT: 'SAGA_ACTIONS/USER_PREFERENCE/GET_ALL_ACCOUNT',
    DELETE_ACCOUNT: 'SAGA_ACTIONS/USER_PREFERENCE/DELETE_ACCOUNT',
    RESTORE_ACCOUNT: 'SAGA_ACTIONS/USER_PREFERENCE/RESTORE_ACCOUNT',
    GET_USER_CONTACT_LIST: 'SAGA_ACTIONS/USER_PREFERENCE/GET_USER_CONTACT_LIST',
    GET_CONTACT_BLOCK_LIST_USER: 'SAGA_ACTIONS/USER_PREFERENCE/GET_CONTACT_BLOCK_LIST_USER',
    REMOVE_ALL_CONTACT_FROM_LIST: 'SAGA_ACTIONS/USER_PREFERENCE/REMOVE_ALL_CONTACT_FROM_LIST',
    REMOVE_ALL_CONTACT_BLOCK_LIST: 'SAGA_ACTIONS/USER_PREFERENCE/REMOVE_ALL_CONTACT_BLOCK_LIST',
    UPLOAD_GALLERY_IMAGE: 'SAGA_ACTIONS/USER_PREFERENCE/UPLOAD_GALLERY_IMAGE',
    GET_ALL_GALLERY_IMAGE: 'SAGA_ACTIONS/USER_PREFERENCE/GET_ALL_GALLERY_IMAGE',
    DELETE_GALLERY_IMAGE: 'SAGA_ACTIONS/USER_PREFERENCE/DELETE_GALLERY_IMAGE',
    DELETE_ALL_GALLERY_IMAGE: 'SAGA_ACTIONS/USER_PREFERENCE/DELETE_ALL_GALLERY_IMAGE',

    UPLOAD_CUSTOMIZED_SOUND: 'SAGA_ACTIONS/USER_PREFERENCE/UPLOAD_CUSTOMIZED_SOUND',
    GET_ALL_CUSTOMIZED_SOUND: 'SAGA_ACTIONS/USER_PREFERENCE/GET_ALL_CUSTOMIZED_SOUND',
    DELETE_CUSTOMIZED_SOUND: 'SAGA_ACTIONS/USER_PREFERENCE/DELETE_CUSTOMIZED_SOUND',

    GET_PARENTAL_CONTROL_INFORMATION: 'SAGA_ACTIONS/USER_PREFERENCE/GET_PARENTAL_CONTROL_INFORMATION',
    SAVE_PARENTAL_PREFERENCE_PASSWORD: 'SAGA_ACTIONS/USER_PREFERENCE/SAVE_PARENTAL_PREFERENCE_PASSWORD',
    DELETE_PARENTAL_PREFERENCE: 'SAGA_ACTIONS/USER_PREFERENCE/DELETE_PARENTAL_PREFERENCE',
    RESET_PARENTAL_PREFERENCE_PASSWORD: 'SAGA_ACTIONS/USER_PREFERENCE/RESET_PARENTAL_PREFERENCE_PASSWORD',
    PARENTAL_FORGOT_PASSWORD_OTP: 'SAGA_ACTIONS/USER_PREFERENCE/PARENTAL_FORGOT_PASSWORD_OTP',
    PARENTAL_SET_PASSWORD: 'SAGA_ACTIONS/USER_PREFERENCE/PARENTAL_SET_PASSWORD',


  },
  FAVOURITE_MENU: {
    MY_ROOM: 'SAGA_ACTIONS/FAVOURITE_MENU/MY_ROOM',
    MY_FAVOURITE_ROOM: 'SAGA_ACTIONS/FAVOURITE_MENU/MY_FAVOURITE_ROOM',
    FAVOURITE_FOLDER_LIST: 'SAGA_ACTIONS/FAVOURITE_MENU/FAVOURITE_FOLDER_LIST',
    CREATE_FAVOURITE_FOLDER: 'SAGA_ACTIONS/FAVOURITE_MENU/CREATE_FAVOURITE_FOLDER',
    ASSIGN_ROOM_TO_FOLDER: 'SAGA_ACTIONS/FAVOURITE_MENU/ASSIGN_ROOM_TO_FOLDER',
    RENAME_ROOM_NAME: 'SAGA_ACTIONS/FAVOURITE_MENU/RENAME_ROOM_NAME',
    DELETE_FAVOURITE_FOLDER: 'SAGA_ACTIONS/FAVOURITE_MENU/DELETE_FAVOURITE_FOLDER',
    LIST_OF_FAVOURITES_ROOM: 'SAGA_ACTIONS/FAVOURITE_MENU/LIST_OF_FAVOURITES_ROOM',
  },
  NOTIFICATION: {
    GET_ALL_NOTIFICATION: 'SAGA_ACTIONS/NOTIFICATION/GET_ALL_NOTIFICATION',
    REMOVE_SINGLE_NOTIFICATION: 'SAGA_ACTIONS/NOTIFICATION/REMOVE_SINGLE_NOTIFICATION',
  },
  PM_WINDOW: {
    SEND_PM: 'SAGA_ACTIONS/PM_WINDOW/SEND_PM',
    PM_WINDOW_DETAILS: 'SAGA_ACTIONS/PM_WINDOW/PM_WINDOW_DETAILS',
    ADD_MEMBER_INTO_PM_WINDOW: 'SAGA_ACTIONS/PM_WINDOW/ADD_MEMBER_INTO_PM_WINDOW',
    REMOVE_MEMBER_FROM_PM_WINDOW: 'SAGA_ACTIONS/PM_WINDOW/REMOVE_MEMBER_FROM_PM_WINDOW',
    SEND_CHAT: 'SAGA_ACTIONS/PM_WINDOW/SEND_CHAT',
    GET_CHAT: 'SAGA_ACTIONS/PM_WINDOW/GET_CHAT',
    ACTIVE_PM_WINDOW: 'SAGA_ACTIONS/PM_WINDOW/ACTIVE_PM_WINDOW',
    SHOW_USERS_LOCATION: 'SAGA_ACTIONS/PM_WINDOW/SHOW_USERS_LOCATION',
    PM_CALL_INITIATE_AND_DISCONNECT: 'SAGA_ACTIONS/PM_WINDOW/PM_CALL_INITIATE_AND_DISCONNECT',
    PM_CALL_ACCEPT_REJECT: 'SAGA_ACTIONS/PM_WINDOW/PM_CALL_ACCEPT_REJECT',
    EXIT_PM_WINDOW: 'SAGA_ACTIONS/PM_WINDOW/EXIT_PM_WINDOW',
    PM_WINDOW_HEADER_MENU_SETTING_ACTION_UPDATE: 'SAGA_ACTIONS/PM_WINDOW/PM_WINDOW_HEADER_MENU_SETTING_ACTION_UPDATE',
    PM_WINDOW_HEADER_MENU_SAVE_DEFAULT_SETTING: 'SAGA_ACTIONS/PM_WINDOW/PM_WINDOW_HEADER_MENU_SAVE_DEFAULT_SETTING',
    PM_WINDOW_HEADER_MENU_RESET_DEFAULT_SETTING: 'SAGA_ACTIONS/PM_WINDOW/PM_WINDOW_HEADER_MENU_RESET_DEFAULT_SETTING',
    PM_READ: 'SAGA_ACTIONS/PM_WINDOW/PM_READ',
    GET_DIG_SOUND_LIST: 'SAGA_ACTIONS/PM_WINDOW/GET_DIG_SOUND_LIST',
    SEND_DIG_SOUND: 'SAGA_ACTIONS/PM_WINDOW/SEND_DIG_SOUND'
  },
  COMMON: {
    GENDER_LIST: 'SAGA_ACTIONS/COMMON/GENDER_LIST',
    COUNTRY_LIST: 'SAGA_ACTIONS/COMMON/COUNTRY_LIST',
    SECRET_QUESTION_LIST: 'SAGA_ACTIONS/COMMON/SECRET_QUESTION_LIST',
    ONLINE_OFFLINE_CONTACT_LIST: 'SAGA_ACTIONS/COMMON/ONLINE_OFFLINE_CONTACT_LIST',
    CHAT_STICKER_CATEGORIES: 'SAGA_ACTIONS/COMMON/CHAT_STICKER_CATEGORIES',
    GET_CATEGORYWISE_ALL_STICKER: 'SAGA_ACTIONS/COMMON/GET_CATEGORYWISE_ALL_STICKER',
    STICKER_BUY: 'SAGA_ACTIONS/COMMON/STICKER_BUY',
    STICKER_OWN: 'SAGA_ACTIONS/COMMON/STICKER_OWN',
    STICKER_GIFT_PURCHASE: 'SAGA_ACTIONS/COMMON/STICKER_GIFT_PURCHASE',
    CMS: 'SAGA_ACTIONS/COMMON/CMS',
  }
}

export * from './site_statics'
export * from './site_urls'
export * from './functions'
export * from './canvasUtils'
export * from './card_utils'